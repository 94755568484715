import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { client } from "./utils/apollo";
import { theme } from "./styles/theme";
import MembershipRequestPage from "./pages/Membership/MembershipRequestPage";
import LandingPage from "./pages/LandingPage";
import MembershipDashboardPage from "./pages/Membership/MembershipDashboardPage";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import ProtectedRoute from "./components/ProtectedRoute";
import Header from "./components/Header";
import MePage from "./pages/User/MePage";
import UserManagementPage from "./pages/Admin/UserManagementPage";
import MembershipDetailPage from "./pages/Membership/MembershipDetailPage";
import UserPage from "./pages/User/UserPage";
import GoogleCallback from "./pages/Auth/GoogleCallback";
import AuthPage from "./pages/Auth/AuthPage";
import { AuthProvider } from "./contexts/AuthContext";
import TripManagementPage from "./pages/Admin/TripManagementPage";
import TripDetailPage from "./pages/Trip/TripDetailPage";
import TripEditPage from "./pages/Admin/TripEditPage";
import "./fonts.css";
import "./i18n";

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Header />
          <AuthProvider>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/auth" element={<AuthPage />} />
              <Route path="/auth/callback" element={<GoogleCallback />} />
              <Route path="/profile" element={<MePage />} />
              <Route path="/trip/:tripId" element={<TripDetailPage />} />
              <Route
                path="/trip/:tripId/edit"
                element={
                  <ProtectedRoute>
                    <TripEditPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/user/:userId" element={<UserPage />} />
              <Route
                path="/membership/:userId"
                element={<MembershipDetailPage />}
              />
              <Route
                path="/membership-request"
                element={
                  <ProtectedRoute>
                    <MembershipRequestPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/membership-dashboard"
                element={
                  <ProtectedRoute>
                    <MembershipDashboardPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/user-management"
                element={
                  <ProtectedRoute allowedRoles={["ADMIN"]}>
                    <UserManagementPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/trips-management"
                element={
                  <ProtectedRoute allowedRoles={["ADMIN"]}>
                    <TripManagementPage />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
