// src/components/Trips/TripCard.tsx

import React from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  useTheme,
  Box,
  IconButton,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Link as RouterLink } from "react-router-dom";

interface Trip {
  id: string;
  title: string;
  startDate: string;
  endDate: string;
  price: number;
  images: string[];
}

interface TripCardProps {
  trip: Trip;
}

const TripCard: React.FC<TripCardProps> = ({ trip }) => {
  const theme = useTheme();

  const startDate = new Date(trip.startDate).toLocaleDateString();
  const endDate = new Date(trip.endDate).toLocaleDateString();

  return (
    <Card
      sx={{
        maxWidth: 345,
        display: "flex",
        flexDirection: "column",
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
        "&:hover": {
          boxShadow: theme.shadows[6],
          transform: "scale(1.02)",
        },
        transition: "all 0.3s ease-in-out",
      }}
    >
      <CardActionArea
        component={RouterLink}
        to={`/trip/${trip.id}`}
        sx={{ flex: 1, position: "relative" }}
      >
        <CardMedia
          component="img"
          sx={{ height: 200, objectFit: "cover", width: "100%" }}
          image={trip.images[0] || "/images/default-trip.jpg"}
          alt={`Image of ${trip.title}`}
        />
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundImage:
              "linear-gradient(to top, rgba(0,0,0,0.6) 0%, transparent 60%)",
            bottom: 0,
            left: 0,
          }}
        />
        <Box sx={{ position: "absolute", top: 8, right: 8, zIndex: 1 }}>
          <FavoriteBorderIcon sx={{ color: "white" }} />
        </Box>
        <CardContent sx={{ position: "absolute", bottom: 0, zIndex: 1 }}>
          <Typography variant="h6" sx={{ color: "white" }}>
            {trip.title}
          </Typography>
          <Typography variant="body2" sx={{ color: "white" }}>
            {startDate} - {endDate}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ justifyContent: "space-between", padding: 1 }}>
        <Typography variant="body1">€{trip.price} / person</Typography>
        <IconButton
          component={RouterLink}
          to={`/trip/${trip.id}`}
          aria-label="go to trip details"
          sx={{ color: theme.palette.primary.main }}
        >
          <ArrowCircleRightIcon fontSize="large" />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default TripCard;
