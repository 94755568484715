import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HeroSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "85vh",
        backgroundImage: `
          linear-gradient(to right, rgba(0, 0, 0, 0.1), transparent),
          url("/images/generated-bg.png")
        `,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        px: { xs: 2, sm: 4, md: 20 },
        py: { xs: 4, sm: 6, md: 10 },
      }}
    >
      <Box
        sx={{
          position: "relative",
          maxWidth: "600px",
          ml: 0,
          mt: { xs: 2, sm: 6, md: 15, lg: 20, xl: 40 },
        }}
      >
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontWeight: "bold",
            textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
            fontSize: { xs: "2.5rem", sm: "3rem", md: "4rem" },
          }}
        >
          {t("level_trevel")}
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: "bold",
            letterSpacing: "2px",
            textTransform: "uppercase",
            textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
            fontSize: { xs: "1.5rem", sm: "1.5rem", md: "2rem" },
            display: "inline-block",
            position: "relative",
          }}
        >
          {t("your_surf_adventure_awaits")}
          {/* Small tilted "Join us" text */}
          <Typography
            variant="subtitle1"
            component="span"
            sx={{
              position: "absolute",
              top: "-20px",
              right: "-300px",
              transform: "rotate(15deg)",
              color: "black",
              fontSize: { xs: "0.8rem", sm: "1rem" },
              textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
            }}
          >
            {t("join_us")}
          </Typography>
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mt: 2,
            textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
            fontSize: { xs: "0.9rem", sm: "1rem" },
          }}
        >
          {t("discover_best_surf_spots")}
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Button
            variant="contained"
            size="large"
            component={RouterLink}
            to="#highlights"
            sx={{
              backgroundColor: "#f6fcf2",
              mr: 2,
              borderRadius: "30px",
              boxShadow: "0px 4px 6px rgba(0,0,0,0.3)",
              color: "#000",
              "&:hover": {
                transform: "scale(1.05)",
                backgroundColor: "#e5f2e0",
              },
            }}
          >
            {t("highlights")}
          </Button>
          <Button
            variant="contained"
            size="large"
            component={RouterLink}
            to="/join"
            sx={{
              borderRadius: "30px",
              backgroundColor: "#fc9033",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#e68a00",
              },
            }}
          >
            {t("join_now")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;
