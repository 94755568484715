import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { Membership } from "../../generated/graphql";

interface MembershipReviewModalProps {
  open: boolean;
  membership: Membership | null;
  onClose: () => void;
  onAccept: () => void;
  onReject: () => void;
}

const MembershipReviewModal: React.FC<MembershipReviewModalProps> = ({
  open,
  membership,
  onClose,
  onAccept,
  onReject,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Review Membership Request</DialogTitle>
      <DialogContent dividers>
        {membership && (
          <Box>
            <Typography variant="h6" gutterBottom>
              User Details
            </Typography>

            <Typography variant="body1">
              <strong>First Name:</strong> {membership.user?.firstname}
            </Typography>
            <Typography variant="body1">
              <strong>Last Name:</strong> {membership.user?.lastname}
            </Typography>
            <Typography variant="body1">
              <strong>Birthday:</strong> {membership.user?.birthDate}
            </Typography>
            <Typography variant="body1">
              <strong>Email:</strong> {membership.user?.email}
            </Typography>
            <Typography variant="body1">
              <strong>Phone number:</strong> {membership.user?.phone}
            </Typography>

            <Typography variant="body1">
              <strong>Contact address:</strong>{" "}
              {membership.user?.contactAddress}
            </Typography>
            <Typography variant="body1">
              <strong>Permanent address:</strong>{" "}
              {membership.user?.permanentAddress}
            </Typography>

            <Typography variant="h6" gutterBottom mt={3}>
              Membership Request Details
            </Typography>
            <Typography variant="body1">
              <strong>Status:</strong> {membership.status}
            </Typography>
            <Typography variant="body1">
              <strong>Requested At:</strong>{" "}
              {new Date(membership.createdAt).toLocaleDateString()}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onReject} color="secondary">
          Reject
        </Button>
        <Button onClick={onAccept} color="primary" variant="contained">
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MembershipReviewModal;
