import React, { useState } from 'react';
import {
  Grid,
  Card,
  CardMedia,
  Button,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import ImageGalleryModal from './ImageGalleryModal';
import { Container, Row, Col } from 'react-bootstrap';
import { relative } from 'path';

interface ImageGalleryProps {
  images: string[];
  maxHeight?: number;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({
  images,
  maxHeight = 300, // Adjusted default maxHeight for better visibility
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const thumbnailSize = isMobile? '60px': '80px';

  const openModal = (index: number) => {
    console.log('TEMP', index);
    setSelectedImageIndex(index);
    setModalOpen(true);
  };

  // Limit the number of images displayed in the grid
  const thumbnailImages = isMobile ? images.slice(1, 2) : images.slice(1, 3);

  return (
    <Row>
      <Col>
        <Box
          sx={{
            position: 'relative',
            borderRadius: '20px',
            overflow: 'hidden',
            marginBottom: '16px',
          }}
        >
          <img
            src={images[0]} // Replace with your actual image
            alt="Main image"
            style={{
              minHeight: '360px',
              maxHeight: '500px',
              width: '100%',
              borderRadius: '20px',
              objectFit: 'cover',
              cursor: 'pointer'
            }}
            onClick={() => openModal(0)}
          />
          {/* Thumbnails */}
          <Box
            sx={{
              position: 'absolute',
              right: isMobile? '10px': '20px',
              top: isMobile? '10px': '30px',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              backgroundColor: '#ffffff67',
              padding: '10px',
              borderRadius: '10px',
            }}
          >
            {thumbnailImages.map((image, index) => (
              <img
                src={image}
                alt="First thumbnail"
                style={{ width: thumbnailSize, height: thumbnailSize, borderRadius: '10px', cursor: 'pointer' }}
                onClick={() => openModal(index + 1)}
                key={index}
              />
            ))}

            <div style={{ position: 'relative' }} onClick={() => openModal(thumbnailImages.length + 1)}>
              <img
                src={images[thumbnailImages.length + 1]}
                alt="First thumbnail"
                style={{ width: thumbnailSize, height: thumbnailSize, borderRadius: '10px' }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: thumbnailSize,
                  height: thumbnailSize,
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  borderRadius: '10px',
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '18px',
                  cursor: 'pointer'
                }}
              >
                +{images.length}
              </Box>
            </div>
          </Box>
        </Box>
      </Col>

      <ImageGalleryModal
        images={images}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        initialIndex={selectedImageIndex}
      />
    </Row>
  );
};

export default ImageGallery;
