// src/pages/MembershipRequestPage.tsx
import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Container, Typography, Alert } from "@mui/material";
import { MEMBERSHIP_REQUEST_MUTATION } from "../../graphql/membership";

const MembershipRequestPage: React.FC = () => {
  const [requestMembership, { loading, error }] = useMutation(
    MEMBERSHIP_REQUEST_MUTATION,
    {
      onCompleted: () => {
        alert("Membership request sent!");
      },
    }
  );

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Request Membership
      </Typography>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => requestMembership()}
        disabled={loading}
      >
        Request Membership
      </Button>
    </Container>
  );
};

export default MembershipRequestPage;
