import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Box,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import {
  UpdateUserDocument,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  User,
} from "../generated/graphql";

interface ProfileEditModalProps {
  open: boolean;
  onClose: () => void;
  user?: User | null;
}

const ProfileEditModal: React.FC<ProfileEditModalProps> = ({
  open,
  onClose,
  user,
}) => {
  const [formData, setFormData] = useState({
    firstname: user?.firstname || "",
    lastname: user?.lastname || "",
    phone: user?.phone || "",
    birthDate: user?.birthDate || "",
    permanentAddress: user?.permanentAddress || "",
    contactAddress: user?.contactAddress || "",
  });

  const [formErrors, setFormErrors] = useState({
    firstname: false,
    lastname: false,
    phone: false,
    birthDate: false,
    permanentAddress: false,
  });

  const [isSaving, setIsSaving] = useState(false);

  const [updateUser] = useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UpdateUserDocument);

  useEffect(() => {
    if (user) {
      setFormData({
        firstname: user.firstname || "",
        lastname: user.lastname || "",
        phone: user.phone || "",
        birthDate: user.birthDate || "",
        permanentAddress: user.permanentAddress || "",
        contactAddress: user.contactAddress || "",
      });
    }
  }, [user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateFields = () => {
    const errors = {
      firstname: formData.firstname === "",
      lastname: formData.lastname === "",
      phone: formData.phone === "",
      birthDate: formData.birthDate === "",
      permanentAddress: formData.permanentAddress === "",
    };
    setFormErrors(errors);
    return !Object.values(errors).some((error) => error === true);
  };

  const handleSave = async () => {
    if (!validateFields()) {
      return;
    }

    setIsSaving(true);
    try {
      await updateUser({
        variables: {
          data: {
            firstname: formData.firstname,
            lastname: formData.lastname,
            birthDate: formData.birthDate,
            permanentAddress: formData.permanentAddress,
            contactAddress: formData.contactAddress,
            phone: formData.phone,
          },
        },
      });
      onClose();
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="edit-profile-dialog"
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "auto" },
          m: { xs: 1, sm: "auto" },
        },
      }}
    >
      <DialogTitle id="edit-profile-dialog">Edit Profile</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              fullWidth
              required
              error={formErrors.firstname}
              helperText={formErrors.firstname && "First name is required"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              fullWidth
              required
              error={formErrors.lastname}
              helperText={formErrors.lastname && "Last name is required"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              fullWidth
              required
              error={formErrors.phone}
              helperText={formErrors.phone && "Phone number is required"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Birth Date"
              name="birthDate"
              value={formData.birthDate}
              onChange={handleChange}
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              required
              error={formErrors.birthDate}
              helperText={formErrors.birthDate && "Birth date is required"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Permanent Address"
              name="permanentAddress"
              value={formData.permanentAddress}
              onChange={handleChange}
              fullWidth
              required
              error={formErrors.permanentAddress}
              helperText={
                formErrors.permanentAddress && "Permanent address is required"
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Contact Address"
              name="contactAddress"
              value={formData.contactAddress}
              onChange={handleChange}
              fullWidth
              helperText="Optional"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", p: 2 }}>
        <Button onClick={onClose} color="secondary" disabled={isSaving}>
          Cancel
        </Button>
        <Box sx={{ position: "relative" }}>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={isSaving}
            sx={{ minWidth: 120 }}
          >
            {isSaving ? "Saving..." : "Save Changes"}
          </Button>
          {isSaving && (
            <CircularProgress
              size={24}
              sx={{
                color: "primary.main",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileEditModal;
