// src/pages/MePage.tsx

import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Button,
  CircularProgress,
  Alert,
  Card,
  CardContent,
} from "@mui/material";
import ProfileEditModal from "../../components/ProfileEditModal";
import EditIcon from "@mui/icons-material/Edit";
import PhoneIcon from "@mui/icons-material/Phone";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import HomeIcon from "@mui/icons-material/Home";
import MailIcon from "@mui/icons-material/Mail";
import { useAuth } from "../../contexts/AuthContext";

const MePage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user, loading, error, refetch } = useAuth();

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        Error fetching profile data.
        <Button
          onClick={() => refetch()}
          variant="outlined"
          size="small"
          sx={{ ml: 1 }}
        >
          Retry
        </Button>
      </Alert>
    );
  }

  if (!user) {
    return <Alert severity="info">No profile data available.</Alert>;
  }

  const openEditModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Box
      sx={{
        p: { xs: 2, md: 3 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Profile
      </Typography>
      <Typography variant="body1" color="text">
        Email: {user.email}
      </Typography>
      <Divider sx={{ width: "100%", mb: 3 }} />

      <Card sx={{ maxWidth: 600, width: "100%" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Profile Details
              </Typography>
            </Grid>

            {/* First Name and Last Name */}
            <Grid item xs={6}>
              <Typography variant="subtitle1">First Name</Typography>
              <Typography variant="body1">
                {user.firstname || "No data"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Last Name</Typography>
              <Typography variant="body1">
                {user.lastname || "No data"}
              </Typography>
            </Grid>

            {/* Phone */}
            <Grid item xs={6}>
              <PhoneIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="subtitle1" display="inline">
                Phone
              </Typography>
              <Typography variant="body1">{user.phone || "No data"}</Typography>
            </Grid>

            {/* Birth Date */}
            <Grid item xs={6}>
              <CalendarTodayIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="subtitle1" display="inline">
                Birth Date
              </Typography>
              <Typography variant="body1">
                {user.birthDate
                  ? new Date(user.birthDate).toLocaleDateString()
                  : "No data"}
              </Typography>
            </Grid>

            {/* Permanent Address */}
            <Grid item xs={6}>
              <HomeIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="subtitle1" display="inline">
                Permanent Address
              </Typography>
              <Typography variant="body1">
                {user.permanentAddress || "No data"}
              </Typography>
            </Grid>

            {/* Contact Address */}
            <Grid item xs={6}>
              <MailIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="subtitle1" display="inline">
                Contact Address
              </Typography>
              <Typography variant="body1">
                {user.contactAddress || "No data"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Sticky Edit Button */}
      <Box
        sx={{
          position: { xs: "fixed", md: "static" },
          bottom: { xs: 16, md: "auto" },
          width: { xs: "100%", md: "auto" },
          px: { xs: 2, md: 0 },
          mt: { xs: 2, md: 3 },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={openEditModal}
          startIcon={<EditIcon />}
          sx={{ width: { xs: "100%", md: "auto" } }}
        >
          Edit Profile
        </Button>
      </Box>

      {/* Profile Edit Modal */}
      <ProfileEditModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        user={user}
      />
    </Box>
  );
};

export default MePage;
