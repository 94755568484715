import { gql } from "@apollo/client";
import { USER_DATA_FRAGMENT } from "./users";

export const TRIP_DATA_FRAGMENT = gql`
  fragment TripData on Trip {
    id
    title
    description
    startDate
    endDate
    price
    numberOfPeople
    temporaryBookingLink
    continent
    country
    images
    includedServices
    excludedServices
    status
    createdAt
    updatedAt
    organizer {
      ...UserData
    }
    guides {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const CREATE_TRIP_MUTATION = gql`
  mutation CreateTrip($input: CreateTripDto!) {
    createTrip(input: $input) {
      ...TripData
    }
  }
  ${TRIP_DATA_FRAGMENT}
`;

export const ACTIVE_TRIPS_QUERY = gql`
  query ActiveTripsConnection {
    activeTrips(first: 20, orderBy: { field: title, direction: desc }) {
      totalCount
      edges {
        cursor
        node {
          ...TripData
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${TRIP_DATA_FRAGMENT}
`;

export const TRIPS_QUERY = gql`
  query Trips(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $orderBy: TripOrder
    $status: TripStatusEnum
  ) {
    trips(
      first: $first
      after: $after
      last: $last
      before: $before
      orderBy: $orderBy
      status: $status
    ) {
      totalCount
      edges {
        cursor
        node {
          ...TripData
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${TRIP_DATA_FRAGMENT}
`;

export const CHANGE_TRIP_STATUS_MUTATION = gql`
  mutation ChangeTripStatus($id: String!, $status: TripStatusEnum!) {
    changeTripStatus(id: $id, status: $status) {
      ...TripData
    }
  }
  ${TRIP_DATA_FRAGMENT}
`;

export const TRIP_QUERY = gql`
  query Trip($id: String!) {
    trip(id: $id) {
      ...TripData
    }
  }
  ${TRIP_DATA_FRAGMENT}
`;

export const UPDATE_TRIP_MUTATION = gql`
  mutation UpdateTrip($id: String!, $data: UpdateTripDto!) {
    updateTrip(id: $id, data: $data) {
      ...TripData
    }
  }
  ${TRIP_DATA_FRAGMENT}
`;
