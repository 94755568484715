import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Formik, Form, Field, FieldProps } from "formik";
import { z } from "zod";

const initialValues = {
  firstname: "",
  lastname: "",
  birthDate: "",
  permanentAddress: "",
  contactAddress: "",
  phone: "",
};

interface MembershipModalProps {
  open: boolean;
  onClose: () => void;
  onAccept: (formData: {
    firstname: string;
    lastname: string;
    birthDate: string;
    permanentAddress: string;
    contactAddress: string;
    phone: string;
  }) => void;
}

// Validation schema using zod
const validationSchema = z.object({
  firstname: z.string().min(1, "First name is required"),
  lastname: z.string().min(1, "Last name is required"),
  birthDate: z.string().min(1, "Birthdate is required"),
  permanentAddress: z.string().min(1, "Permanent address is required"),
  contactAddress: z.string().min(1, "Contact address is required"),
  phone: z.string().min(1, "Phone number is required"),
});

// Function to convert zod errors to Formik-compatible format
const validateForm = (values: typeof initialValues) => {
  try {
    validationSchema.parse(values);
    return {};
  } catch (error) {
    const formErrors: { [key: string]: string } = {};
    if (error instanceof z.ZodError) {
      error.errors.forEach((err) => {
        if (err.path.length > 0) {
          formErrors[err.path[0]] = err.message;
        }
      });
    }
    return formErrors;
  }
};

const MembershipModal: React.FC<MembershipModalProps> = ({
  open,
  onClose,
  onAccept,
}) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const handleSubmit = (values: typeof initialValues) => {
    if (acceptedTerms) {
      onAccept(values);
      onClose();
    }
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptedTerms(event.target.checked);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Request Membership</DialogTitle>
      <DialogContent dividers>
        <Box>
          <Typography variant="body1" paragraph>
            Elektronická žiadosť o členstvo v občianskom združení: Level trevel
            o.z.
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          validate={validateForm}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, touched, errors }) => (
            <Form>
              <Box mb={2}>
                <Field name="firstname">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      label="First Name"
                      fullWidth
                      error={touched.firstname && Boolean(errors.firstname)}
                      helperText={touched.firstname && errors.firstname}
                      margin="normal"
                    />
                  )}
                </Field>
                <Field name="lastname">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      fullWidth
                      error={touched.lastname && Boolean(errors.lastname)}
                      helperText={touched.lastname && errors.lastname}
                      margin="normal"
                    />
                  )}
                </Field>
                <Field name="birthDate">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      label="Birth Date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      error={touched.birthDate && Boolean(errors.birthDate)}
                      helperText={touched.birthDate && errors.birthDate}
                      margin="normal"
                    />
                  )}
                </Field>
                <Field name="permanentAddress">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      label="Permanent Address"
                      fullWidth
                      error={
                        touched.permanentAddress &&
                        Boolean(errors.permanentAddress)
                      }
                      helperText={
                        touched.permanentAddress && errors.permanentAddress
                      }
                      margin="normal"
                    />
                  )}
                </Field>
                <Field name="contactAddress">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      label="Contact Address"
                      fullWidth
                      error={
                        touched.contactAddress && Boolean(errors.contactAddress)
                      }
                      helperText={
                        touched.contactAddress && errors.contactAddress
                      }
                      margin="normal"
                    />
                  )}
                </Field>
                <Field name="phone">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      label="Phone Number"
                      fullWidth
                      error={touched.phone && Boolean(errors.phone)}
                      helperText={touched.phone && errors.phone}
                      margin="normal"
                    />
                  )}
                </Field>
              </Box>

              <Box mt={3}>
                <Typography
                  variant="body2"
                  paragraph
                  sx={{ fontWeight: "bold" }}
                >
                  žiadam o členstvo v občianskom združení Level trevel o.z.
                </Typography>

                <Typography variant="body2" paragraph>
                  Svojím podpisom potvrdzujem, že som oboznámený so stanovami
                  občianskeho združenia Level trevel o.z. a zaväzujem sa ich
                  rešpektovať. V zmysle zákona Zák. č. 18/2018 Z.z. o ochrane
                  osobných údajov (GDPR) v znení neskorších predpisov súhlasím
                  so správou, spracovaním a uchovaním mojich osobných údajov
                  uvedených vo formulári pre o.z. Level Trevel, súvisiacich s
                  registráciou na uchádzanie sa o členstvo v o.z. Level Trevel.
                  Čestne vyhlasujem, že všetky uvedené informácie sú pravdivé a
                  poskytnutie údajov je dobrovoľné na základe vlastného
                  rozhodnutia. Tieto údaje môžu byť spracované výlučne pre účely
                  nájdenia vhodného kandidáta/ky za člena/členku o.z. Level
                  Trevel a pre účely databázy kandidátov/iek, členov/členiek.
                  Súhlas je daný na dobu určitú po dobu 10 rokov, ktorý je možné
                  kedykoľvek písomne odvolať do lehoty 30 dní.
                </Typography>

                <Typography variant="body2" paragraph>
                  Účel spracúvania osobných údajov je evidencia členov Level
                  trevel o.z. a zahŕňa zasielanie informácií o aktivitách a
                  novinkách o činnosti občianskeho združenia. Ďalej informácie o
                  podujatiach, ktoré občianske združenie organizuje, alebo na
                  nich partnersky spolupracuje. (Ako dotknutá osoba máte právo
                  poskytnutý súhlas so spracovaním osobných údajov kedykoľvek
                  odvolať, písomne na poštovú adresu Level trevel o.z. alebo
                  mailom na adresu info@leveltrevel.sk)
                </Typography>

                <Typography
                  variant="body2"
                  paragraph
                  sx={{ fontWeight: "light", fontSize: 12 }}
                >
                  POZNÁMKA K PRIHLÁŠKE: Údaje poskytnuté v prihláške (okrem
                  kontaktného mena) sú internou záležitosťou občianskeho
                  združenia Level trevel o.z.. Tieto údaje sú k dispozícií len
                  pre členov výkonného výboru a osobu predsedu združenia. Bez
                  výslovného súhlasu člena, nebudú tieto údaje poskytnuté iným
                  členom občianskeho združenia.
                </Typography>

                <Typography
                  variant="body2"
                  paragraph
                  sx={{ fontWeight: "light", fontSize: 12 }}
                >
                  Týmto potvrdzujem, že elektronické odoslanie tohto formulára
                  považujem za záväzné a rovnocenné s vlastnoručným podpisom na
                  papierovej verzii dokumentu. Rozumiem, že môj súhlas a
                  odoslanie údajov majú právnu váhu a som si vedomý/á, že
                  poskytnuté údaje budú spracované v súlade so stanovenými
                  podmienkami a predpismi o.z. Level trevel o.z.
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      checked={acceptedTerms}
                      onChange={handleToggleChange}
                      color="primary"
                      required
                    />
                  }
                  label="Súhlasím s vyššie uvedenými podmienkami"
                  sx={{ marginBottom: 2 }}
                />
              </Box>

              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Submit Request
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default MembershipModal;
