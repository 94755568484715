import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#f67121",
    },
    secondary: {
      main: "#50c2d5",
    },
    error: {
      main: "#f44336",
    },
    warning: {
      main: "#ff9800",
    },
  },
  typography: {
    fontFamily: "CustomSurfFont, Arial, sans-serif",
    h1: {
      fontWeight: 500,
      fontFamily: "CustomSurfFont, Arial, sans-serif",
    },
    h2: {
      fontWeight: 500,
      fontFamily: "CustomSurfFont, Arial, sans-serif",
    },
    h3: {
      fontWeight: 500,
      fontFamily: "CustomSurfFont, Arial, sans-serif",
    },
    h4: {
      fontWeight: 500,
      fontFamily: "CustomSurfFont, Arial, sans-serif",
    },
    h5: {
      fontWeight: 500,
      fontFamily: "CustomSurfFont, Arial, sans-serif",
    },
  },
});
