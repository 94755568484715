import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Paper,
  Avatar,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import TripsListContainer from "../components/Trips/TripsListContainer";
import HeroSection from "../components/LandingPage/HeroSection";
import { useTranslation } from "react-i18next";

interface TeamMember {
  name: string;
  image: string;
  role?: string;
  linkedIn?: string;
  instagram?: string;
  twitter?: string;
  facebook?: string;
}

const teamMembers: TeamMember[] = [
  {
    name: "Rasťo Kopál",
    image: "/images/members/rasto.jpg",
    role: "Community Captain & Master shredder",
    instagram: "https://www.instagram.com/_rastiik/",
  },
  {
    name: "Jakub Fačkovec",
    image: "/images/members/jakub.jpg",
    role: "Founder & Chief Wave Enjoyer",
    instagram: "https://www.instagram.com/jakuposlav/",
  },
  {
    name: "Verča Huláková",
    image: "/images/members/veronika_h.png",
    role: "Master Planner & Surf Safari Guru",
    instagram: "https://www.instagram.com/veeercaa/",
  },
  {
    name: "Roman Berešík",
    image: "/images/members/roman_b.png",
    role: "Event Maestro & Wave Whisperer",
    instagram: "https://www.instagram.com/romanberesik/",
    twitter: "https://twitter.com/romanberesik",
    facebook: "https://facebook.com/romanberesik",
  },
  {
    name: "Tonka Rošková",
    image: "/images/members/antonia_r.jpg",
    role: "Master planner & Longboard Queen",
    instagram: "https://www.instagram.com/tonickaroskova/",
  },
  {
    name: "Naďa Baničová",
    image: "/images/members/nada_b.png",
    role: "Event Ninja & Fun Facilitator",
    instagram: "https://www.instagram.com/wnadka_x/",
  },
  {
    name: "Viliam Glemba",
    image: "/images/members/viliam_g.png",
    role: "Marketing Maverick & Hype Generator",
    instagram: "https://www.instagram.com/iamwill_g/",
  },
  {
    name: "Martin Železník",
    image: "/images/members/martin_z.jpg",
    role: "Social Media Wizard & Hashtag Hero",
    instagram: "https://www.instagram.com/zeleznikmp/",
  },
];

const LandingPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const cards = [
    { title: t("total_customers"), value: "150+" },
    { title: t("years_of_experience"), value: "2+" },
    { title: t("total_destinations"), value: "5+" },
    { title: t("average_rating"), value: "4.8" },
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box>
      <HeroSection />

      {/* Active Trips Section */}
      <Box
        sx={{ backgroundColor: "#f5f5f5", py: 3, mb: 5 }}
        id="upcoming-trips"
      >
        <Container>
          <Typography variant="h4" gutterBottom align="center">
            {t("upcoming_trips")}
          </Typography>
          <TripsListContainer />
        </Container>
      </Box>

      {/* Highlights Section */}
      <Container sx={{ mt: 5, mb: 5 }}>
        <Grid container spacing={3}>
          {cards.map((card, index) => (
            <Grid item xs={6} sm={3} key={index}>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  textAlign: "center",
                }}
              >
                <Typography variant="h4" color="primary" gutterBottom>
                  {card.value}
                </Typography>
                <Typography variant="subtitle1">{card.title}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* About Us Section */}
      <Box sx={{ backgroundColor: "#f5f5f5", py: 8 }}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom>
                {t("who_we_are")}
              </Typography>
              <Typography variant="body1" paragraph>
                {t("about_us_paragraph")}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to="/about"
              >
                {t("learn_more")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/images/lanzarote-people.jpeg"
                alt="Surfing"
                sx={{
                  width: "100%",
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Features Section */}
      <Box sx={{ py: 8 }}>
        <Container>
          <Typography variant="h4" gutterBottom align="center">
            {t("why_join_us")}
          </Typography>
          <Grid container spacing={5} sx={{ mt: 2 }}>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: "center", px: 2 }}>
                <Box
                  component="img"
                  src="/images/trip1.jpeg"
                  alt="Surfing"
                  sx={{ width: "250px", mb: 2 }}
                />
                <Typography variant="h6" gutterBottom>
                  {t("surfing_adventures")}
                </Typography>
                <Typography variant="body1">
                  {t("surfing_adventures_text")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: "center", px: 2 }}>
                <Box
                  component="img"
                  src="/images/trip2.jpeg"
                  alt="Community"
                  sx={{ width: "250px", mb: 2 }}
                />
                <Typography variant="h6" gutterBottom>
                  {t("vibrant_community")}
                </Typography>
                <Typography variant="body1">
                  {t("vibrant_community_text")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: "center", px: 2 }}>
                <Box
                  component="img"
                  src="/images/trip3.jpeg"
                  alt="Exploration"
                  sx={{ width: "250px", mb: 2 }}
                />
                <Typography variant="h6" gutterBottom>
                  {t("explore_nature")}
                </Typography>
                <Typography variant="body1">
                  {t("explore_nature_text")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Testimonials Section */}
      <Box sx={{ py: 8, backgroundColor: "#e0f7fa" }}>
        <Container>
          <Typography variant="h4" gutterBottom align="center">
            {t("what_our_members_say")}
          </Typography>
          <Grid container spacing={5} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="body1" paragraph>
                  {t("testimonial_rasto")}
                </Typography>
                <Typography variant="subtitle1" align="right">
                  {t("rasto")}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="body1" paragraph>
                  {t("testimonial_jakub")}
                </Typography>
                <Typography variant="subtitle1" align="right">
                  {t("jakub")}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Team Section */}
      <Box sx={{ py: 8, backgroundColor: "#f5f5f5" }}>
        <Container>
          <Typography variant="h4" gutterBottom align="center">
            {t("meet_our_team")}
          </Typography>

          {/* Carousel */}
          <Box sx={{ mt: 5 }}>
            <Slider {...sliderSettings}>
              {teamMembers.map((member) => (
                <Box
                  key={member.name}
                  sx={{
                    px: 2,
                    outline: "none", // Remove focus outline on slide
                  }}
                >
                  <Paper
                    elevation={3}
                    sx={{
                      p: 3,
                      mb: 2,
                      textAlign: "center",
                      borderRadius: "20px",
                    }}
                  >
                    <Avatar
                      alt={member.name}
                      src={member.image}
                      sx={{
                        width: 120,
                        height: 120,
                        mx: "auto",
                        mb: 2,
                      }}
                    />
                    <Typography variant="h6">{member.name}</Typography>
                    {member.role && (
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        gutterBottom
                      >
                        {member.role}
                      </Typography>
                    )}
                    {/* Social Icons */}
                    <Box sx={{ mt: 1 }}>
                      {member.instagram && (
                        <IconButton
                          component="a"
                          href={member.instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="Instagram"
                          sx={{ color: "#E4405F" }}
                        >
                          <InstagramIcon />
                        </IconButton>
                      )}
                      {member.twitter && (
                        <IconButton
                          component="a"
                          href={member.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="Twitter"
                          sx={{ color: "#1DA1F2" }}
                        >
                          <TwitterIcon />
                        </IconButton>
                      )}
                      {member.facebook && (
                        <IconButton
                          component="a"
                          href={member.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="Facebook"
                          sx={{ color: "#1877F2" }}
                        >
                          <FacebookIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Paper>
                </Box>
              ))}
            </Slider>
          </Box>
        </Container>
      </Box>

      {/* Call to Action */}
      <Box
        sx={{
          py: 8,
          backgroundImage: 'url("/images/cta-background.jpg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {/* Overlay */}
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.6)",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        ></Box>
        <Container sx={{ position: "relative", zIndex: 2 }}>
          <Typography variant="h4" gutterBottom>
            {t("ready_to_start_your_adventure")}
          </Typography>
          <Typography variant="body1" paragraph>
            {t("join_us_on_next_trip")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={RouterLink}
            to="/join"
          >
            {t("join_now")}
          </Button>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;
