// src/components/Trips/EditTripForm.tsx

import React, { useState } from "react";
import { Grid, TextField, Typography, Button, Box, Chip } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { TRIP_QUERY, UPDATE_TRIP_MUTATION } from "../../graphql/trips";
import { Trip } from "../../generated/graphql";
import EditableAccordion from "./EditableAccordion";
// import { useAuth } from "../../contexts/AuthContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

interface EditTripFormProps {
  trip: Trip;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  temporaryBookingLink: Yup.string().url("Must be a valid URL").optional(),
  description: Yup.string().required("Description is required"),
  startDate: Yup.date().required("Start date is required"),
  endDate: Yup.date()
    .required("End date is required")
    .min(Yup.ref("startDate"), "End date cannot be before start date"),
  numberOfPeople: Yup.number()
    .min(1, "At least one person")
    .required("Number of people is required"),
  price: Yup.number()
    .min(0, "Price should be positive")
    .required("Price is required"),
  country: Yup.string().required("Country is required"),
  images: Yup.array().min(1, "At least one image is required"),
  includedServices: Yup.array().min(
    1,
    "At least one included service is required"
  ),
  excludedServices: Yup.array().min(
    1,
    "At least one excluded service is required"
  ),
  //   continent: Yup.string()
  //     .oneOf(Object.values(ContinentEnum), "Invalid continent")
  //     .required("Continent is required"),
});

const EditTripForm: React.FC<EditTripFormProps> = ({ trip }) => {
  //   const { user } = useAuth();
  const navigate = useNavigate();

  const [updateTrip, { loading }] = useMutation(UPDATE_TRIP_MUTATION, {
    refetchQueries: [
      {
        query: TRIP_QUERY,
        variables: { id: trip.id },
      },
    ],
  });

  const [imageUrls, setImageUrls] = useState<string[]>(trip.images);
  const [imageUrlInput, setImageUrlInput] = useState<string>("");

  const handleUpdate = async (values: any) => {
    try {
      await updateTrip({
        variables: {
          id: trip.id,
          data: {
            ...values,
            // guidesIds: [user?.id], // TODO
            images: imageUrls,
          },
        },
      });
      console.log("Trip updated successfully");

      navigate(`/trip/${trip.id}`, { replace: true });
    } catch (err: any) {
      console.error("Error updating trip", err);
      // Optionally, set an error state to display
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
  ];

  return (
    <Formik
      initialValues={{
        title: trip.title || "",
        description: trip.description || "",
        temporaryBookingLink: trip.temporaryBookingLink || "",
        startDate: DateTime.fromISO(trip.startDate),
        endDate: DateTime.fromISO(trip.endDate),
        numberOfPeople: trip.numberOfPeople || 1,
        price: trip.price || 0,
        country: trip.country || "",
        images: trip.images || [],
        includedServices: trip.includedServices || [],
        excludedServices: trip.excludedServices || [],
        // continent: trip.continent || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        handleUpdate(values)
          .then(() => {
            setSubmitting(false);
            setStatus({ success: true });
          })
          .catch((error) => {
            setSubmitting(false);
            setStatus({ success: false, message: error.message });
          });
      }}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        isSubmitting,
        status,
      }) => {
        const handleAddImageUrl = (
          event: React.KeyboardEvent<HTMLDivElement>
        ) => {
          if (event.key === "Enter" && imageUrlInput.trim()) {
            const newImageUrls = [...imageUrls, imageUrlInput.trim()];
            setImageUrls(newImageUrls);
            setFieldValue("images", newImageUrls);
            setImageUrlInput("");
            event.preventDefault();
          }
        };

        const handleDeleteImageUrl = (url: string) => {
          const newImageUrls = imageUrls.filter((imgUrl) => imgUrl !== url);
          setImageUrls(newImageUrls);
          setFieldValue("images", newImageUrls);
        };

        return (
          <Form>
            <Grid container spacing={3}>
              {/* Trip Title */}
              <Grid item xs={12}>
                <TextField
                  label="Trip Title"
                  name="title"
                  fullWidth
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Description
                </Typography>
                <ReactQuill
                  value={values.description}
                  onChange={(content) => setFieldValue("description", content)}
                  modules={modules}
                  formats={formats}
                  theme="snow"
                />
                {touched.description && errors.description && (
                  <Typography variant="caption" color="error">
                    {errors.description}
                  </Typography>
                )}
              </Grid>

              {/* Temporary Booking Link */}
              <Grid item xs={12}>
                <TextField
                  label="Temporary Booking Link"
                  name="temporaryBookingLink"
                  fullWidth
                  value={values.temporaryBookingLink}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.temporaryBookingLink &&
                    Boolean(errors.temporaryBookingLink)
                  }
                  helperText={
                    touched.temporaryBookingLink && errors.temporaryBookingLink
                  }
                />
              </Grid>

              {/* Start Date */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Start Date"
                  name="startDate"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={values.startDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.startDate && Boolean(errors.startDate)}
                  //   helperText={touched.startDate && errors.startDate} // TODO
                />
              </Grid>

              {/* End Date */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="End Date"
                  name="endDate"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={values.endDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.endDate && Boolean(errors.endDate)}
                  //   helperText={touched.endDate && errors.endDate} // TODO fix this
                />
              </Grid>

              {/* Number of People */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Number of People"
                  name="numberOfPeople"
                  type="number"
                  fullWidth
                  value={values.numberOfPeople}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.numberOfPeople && Boolean(errors.numberOfPeople)
                  }
                  helperText={touched.numberOfPeople && errors.numberOfPeople}
                  InputProps={{ inputProps: { min: 1 } }}
                />
              </Grid>

              {/* Price */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Price (€)"
                  name="price"
                  type="number"
                  fullWidth
                  value={values.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.price && Boolean(errors.price)}
                  helperText={touched.price && errors.price}
                  InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                />
              </Grid>

              {/* Country */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Country"
                  name="country"
                  fullWidth
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country && errors.country}
                />
              </Grid>

              {/* Continent */}
              {/* <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={touched.continent && Boolean(errors.continent)}
                >
                  <InputLabel id="continent-label">Continent</InputLabel>
                  <Select
                    labelId="continent-label"
                    label="Continent"
                    name="continent"
                    value={values.continent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {Object.values(ContinentEnum).map((continent) => (
                      <MenuItem key={continent} value={continent}>
                        {continent}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.continent && errors.continent && (
                    <Typography variant="caption" color="error">
                      {errors.continent}
                    </Typography>
                  )}
                </FormControl>
              </Grid> */}

              {/* Image URLs */}
              <Grid item xs={12}>
                <TextField
                  label="Add Image URL"
                  name="imageUrlInput"
                  fullWidth
                  value={imageUrlInput}
                  onChange={(e) => setImageUrlInput(e.target.value)}
                  onKeyPress={handleAddImageUrl}
                  helperText="Press Enter to add image URL"
                />
              </Grid>
              <Grid item xs={12}>
                {imageUrls.map((url, index) => (
                  <Chip
                    key={index}
                    label={url}
                    onDelete={() => handleDeleteImageUrl(url)}
                    color="primary"
                    sx={{ margin: 0.5 }}
                  />
                ))}
                {touched.images && errors.images && (
                  <Typography variant="caption" color="error">
                    {errors.images}
                  </Typography>
                )}
              </Grid>

              {/* Included Services */}
              <Grid item xs={12}>
                <EditableAccordion
                  label="What's Included?"
                  items={values.includedServices}
                  onItemsChange={(items: any) =>
                    setFieldValue("includedServices", items)
                  }
                />
                {touched.includedServices && errors.includedServices && (
                  <Typography variant="caption" color="error">
                    {errors.includedServices}
                  </Typography>
                )}
              </Grid>

              {/* Excluded Services */}
              <Grid item xs={12}>
                <EditableAccordion
                  label="What is NOT Included?"
                  items={values.excludedServices}
                  onItemsChange={(items: any) =>
                    setFieldValue("excludedServices", items)
                  }
                />
                {touched.excludedServices && errors.excludedServices && (
                  <Typography variant="caption" color="error">
                    {errors.excludedServices}
                  </Typography>
                )}
              </Grid>
            </Grid>

            {/* Status Messages */}
            {status && status.success && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" color="success.main">
                  Trip updated successfully!
                </Typography>
              </Box>
            )}
            {status && status.success === false && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" color="error">
                  {status.message ||
                    "An error occurred while updating the trip."}
                </Typography>
              </Box>
            )}

            {/* Submit Button */}
            <Box sx={{ mt: 3, textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                disabled={isSubmitting || loading}
              >
                {isSubmitting || loading ? "Updating..." : "Update Trip"}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditTripForm;
