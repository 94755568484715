import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Link as RouterLink } from "react-router-dom";
import {
  Container,
  Typography,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Menu,
  MenuItem,
  IconButton,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  MEMBERSHIPS_QUERY,
  UPDATE_MEMBERSHIP_STATUS_MUTATION,
  DELETE_MEMBERSHIP_MUTATION,
} from "../../graphql/membership";
import {
  Membership,
  MembershipStatusEnum,
  MembershipOrder,
  Role,
} from "../../generated/graphql";
import {
  UPDATE_USER_ROLE_MUTATION,
  DELETE_USER_MUTATION,
} from "../../graphql/users";
import MembershipReviewModal from "../../components/Memberships/MembershipReviewModal";
import { isAdminGuard } from "../../utils/isAdminGuard";
import { useAuth } from "../../contexts/AuthContext";

const UserManagementPage: React.FC = () => {
  const { user } = useAuth();
  const [statusFilter, setStatusFilter] = useState<MembershipStatusEnum>(
    MembershipStatusEnum.Active
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [cursor, setCursor] = useState<string | null>(null);

  const {
    data: membershipsData,
    loading: membershipsLoading,
    error: membershipsError,
    fetchMore,
    refetch,
  } = useQuery(MEMBERSHIPS_QUERY, {
    variables: {
      first: rowsPerPage,
      after: cursor,
      orderBy: { field: "createdAt", direction: "desc" } as MembershipOrder,
      status: statusFilter,
    },
    fetchPolicy: "cache-and-network",
  });

  const [updateMembership] = useMutation(UPDATE_MEMBERSHIP_STATUS_MUTATION);
  const [updateUserRole] = useMutation(UPDATE_USER_ROLE_MUTATION);
  const [deleteMembership] = useMutation(DELETE_MEMBERSHIP_MUTATION);
  const [deleteUser] = useMutation(DELETE_USER_MUTATION);

  const [selectedMembership, setSelectedMembership] =
    useState<Membership | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // State for actions menu
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [selectedUser, setSelectedUser] = useState<Membership | null>(null);

  // State for dialogs
  const [isChangeRoleDialogOpen, setIsChangeRoleDialogOpen] = useState(false);
  const [isDeleteMembershipDialogOpen, setIsDeleteMembershipDialogOpen] =
    useState(false);
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false);
  const [newRole, setNewRole] = useState<Role>(Role.User);

  // Snackbar state for feedback messages
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  // Guard to prevent non-admins from accessing the page
  if (!isAdminGuard(user)) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Alert severity="error">
          You do not have permission to access this page.
        </Alert>
      </Container>
    );
  }

  // Open review modal for pending memberships
  const handleOpenModal = (membership: Membership) => {
    setSelectedMembership(membership);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedMembership(null);
  };

  const handleAcceptMembership = async (userId: string) => {
    try {
      await updateMembership({
        variables: { userId, status: MembershipStatusEnum.Active },
      });
      setSnackbarMessage("Membership accepted.");
      setSnackbarOpen(true);
      refetch();
    } catch (error) {
      console.error(error);
      setSnackbarMessage("Error accepting membership.");
      setSnackbarOpen(true);
    }
    handleCloseModal();
  };

  const handleRejectMembership = async (userId: string) => {
    try {
      await updateMembership({
        variables: { userId, status: MembershipStatusEnum.Rejected },
      });
      setSnackbarMessage("Membership rejected.");
      setSnackbarOpen(true);
      refetch();
    } catch (error) {
      console.error(error);
      setSnackbarMessage("Error rejecting membership.");
      setSnackbarOpen(true);
    }
    handleCloseModal();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);

    if (newPage > page && membershipsData?.memberships?.pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: membershipsData?.memberships?.pageInfo?.endCursor,
        },
      }).then((fetchMoreResult) => {
        setCursor(
          fetchMoreResult?.data?.memberships?.pageInfo?.endCursor || null
        );
      });
    } else if (
      newPage < page &&
      membershipsData?.memberships?.pageInfo?.hasPreviousPage
    ) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          before: membershipsData?.memberships?.pageInfo?.startCursor,
        },
      }).then((fetchMoreResult) => {
        setCursor(
          fetchMoreResult?.data?.memberships?.pageInfo?.startCursor || null
        );
      });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setCursor(null);
  };

  // Handle status filter change
  const handleStatusFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: MembershipStatusEnum | null
  ) => {
    if (newStatus) {
      setStatusFilter(newStatus);
      setPage(0); // Reset to first page when switching status
      setCursor(null); // Reset cursor for pagination
    }
  };

  // Handle actions menu
  const handleActionsMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    membership: Membership
  ) => {
    setActionsAnchorEl(event.currentTarget);
    setSelectedUser(membership);
  };

  const handleActionsMenuClose = () => {
    setActionsAnchorEl(null);
  };

  const handleOpenChangeRoleDialog = () => {
    if (selectedUser && selectedUser.user) {
      setNewRole((selectedUser.user.role as Role) || Role.User);
      setIsChangeRoleDialogOpen(true);
    }
    handleActionsMenuClose();
  };

  const handleCloseChangeRoleDialog = () => {
    setIsChangeRoleDialogOpen(false);
  };

  const handleConfirmChangeRole = async () => {
    if (selectedUser && selectedUser.user) {
      try {
        await updateUserRole({
          variables: { id: selectedUser.user.id, role: newRole },
        });
        setSnackbarMessage("User role updated.");
        setSnackbarOpen(true);
        refetch();
      } catch (error) {
        console.error(error);
        setSnackbarMessage("Error updating user role.");
        setSnackbarOpen(true);
      }
      handleCloseChangeRoleDialog();
    }
  };

  const handleDeleteMembership = () => {
    setIsDeleteMembershipDialogOpen(true);
    handleActionsMenuClose();
  };

  const handleCloseDeleteMembershipDialog = () => {
    setIsDeleteMembershipDialogOpen(false);
  };

  const handleConfirmDeleteMembership = async () => {
    if (selectedUser && selectedUser.user) {
      try {
        await deleteMembership({
          variables: { userId: selectedUser.user.id },
        });
        setSnackbarMessage("Membership deleted.");
        setSnackbarOpen(true);
        refetch();
      } catch (error) {
        console.error(error);
        setSnackbarMessage("Error deleting membership.");
        setSnackbarOpen(true);
      }
      handleCloseDeleteMembershipDialog();
    }
  };

  const handleDeleteUser = () => {
    setIsDeleteUserDialogOpen(true);
    handleActionsMenuClose();
  };

  const handleCloseDeleteUserDialog = () => {
    setIsDeleteUserDialogOpen(false);
  };

  const handleConfirmDeleteUser = async () => {
    if (selectedUser && selectedUser.user) {
      try {
        await deleteUser({
          variables: { id: selectedUser.user.id },
        });
        setSnackbarMessage("User deleted.");
        setSnackbarOpen(true);
        refetch();
      } catch (error) {
        console.error(error);
        setSnackbarMessage("Error deleting user.");
        setSnackbarOpen(true);
      }
      handleCloseDeleteUserDialog();
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (membershipsLoading)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );

  if (membershipsError) {
    console.error(membershipsError?.message);
    return <Alert severity="error">Error loading data.</Alert>;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Admin Dashboard
      </Typography>

      {/* Status Filter Toggle */}
      <Box mb={3}>
        <ToggleButtonGroup
          value={statusFilter}
          exclusive
          onChange={handleStatusFilterChange}
          aria-label="membership status filter"
        >
          <ToggleButton
            value={MembershipStatusEnum.Active}
            aria-label="active memberships"
          >
            Active
          </ToggleButton>
          <ToggleButton
            value={MembershipStatusEnum.Pending}
            aria-label="pending memberships"
          >
            Pending
          </ToggleButton>
          <ToggleButton
            value={MembershipStatusEnum.Rejected}
            aria-label="rejected memberships"
          >
            Rejected
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Memberships Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Requested At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {membershipsData?.memberships?.edges.map(
              ({ node }: { node: Membership }) => (
                <TableRow key={node.id}>
                  <TableCell>
                    {node.user?.firstname && node.user?.lastname ? (
                      <Button
                        component={RouterLink}
                        to={`/user/${node.user?.id}`}
                        variant="text"
                        color="primary"
                      >
                        {`${node.user?.firstname} ${node.user?.lastname}`}
                      </Button>
                    ) : (
                      "No Name"
                    )}
                  </TableCell>
                  <TableCell>{node.status}</TableCell>
                  <TableCell>{node.user?.role || "USER"}</TableCell>
                  <TableCell>
                    {new Date(node.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {/* Existing actions */}
                      {statusFilter === MembershipStatusEnum.Pending ? (
                        <Button
                          variant="outlined"
                          onClick={() => handleOpenModal(node)}
                        >
                          Review
                        </Button>
                      ) : statusFilter === MembershipStatusEnum.Active ? (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => handleRejectMembership(node.user?.id!)}
                        >
                          Reject
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleAcceptMembership(node.user?.id!)}
                        >
                          Accept
                        </Button>
                      )}
                      {/* Actions Menu */}
                      <IconButton
                        onClick={(e) => handleActionsMenuOpen(e, node)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>

        {/* Pagination */}
        <TablePagination
          component="div"
          count={membershipsData?.memberships?.totalCount || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* Actions Menu */}
      <Menu
        anchorEl={actionsAnchorEl}
        open={Boolean(actionsAnchorEl)}
        onClose={handleActionsMenuClose}
      >
        <MenuItem onClick={handleOpenChangeRoleDialog}>Change Role</MenuItem>
        <MenuItem onClick={handleDeleteMembership}>Delete Membership</MenuItem>
        <MenuItem onClick={handleDeleteUser}>Delete User</MenuItem>
      </Menu>

      {/* Change Role Dialog */}
      <Dialog
        open={isChangeRoleDialogOpen}
        onClose={handleCloseChangeRoleDialog}
      >
        <DialogTitle>Change User Role</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              value={newRole}
              onChange={(e) => setNewRole(e.target.value as Role)}
            >
              {Object.values(Role).map((role) => (
                <FormControlLabel
                  key={role}
                  value={role}
                  control={<Radio />}
                  label={role}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseChangeRoleDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmChangeRole} color="primary">
            Update Role
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Membership Dialog */}
      <Dialog
        open={isDeleteMembershipDialogOpen}
        onClose={handleCloseDeleteMembershipDialog}
      >
        <DialogTitle>Delete Membership</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user's membership?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteMembershipDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDeleteMembership} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete User Dialog */}
      <Dialog
        open={isDeleteUserDialogOpen}
        onClose={handleCloseDeleteUserDialog}
      >
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteUserDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDeleteUser} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />

      {/* Membership Review Modal */}
      <MembershipReviewModal
        open={isModalOpen}
        membership={selectedMembership}
        onClose={handleCloseModal}
        onAccept={() => handleAcceptMembership(selectedMembership?.user?.id!)}
        onReject={() => handleRejectMembership(selectedMembership?.user?.id!)}
      />
    </Container>
  );
};

export default UserManagementPage;
