import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JWT: { input: any; output: any; }
};

export type AcceptParticipationInput = {
  tripId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type Auth = {
  __typename?: 'Auth';
  /** JWT access token */
  accessToken: Scalars['JWT']['output'];
  /** JWT refresh token */
  refreshToken: Scalars['JWT']['output'];
  user: User;
};

export type ChangePasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export enum ContinentEnum {
  Africa = 'AFRICA',
  Antarctica = 'ANTARCTICA',
  Asia = 'ASIA',
  Australia = 'AUSTRALIA',
  Europe = 'EUROPE',
  NorthAmerica = 'NORTH_AMERICA',
  Oceania = 'OCEANIA',
  SouthAmerica = 'SOUTH_AMERICA'
}

export type CreateMembershipInput = {
  birthDate: Scalars['String']['input'];
  contactAddress: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  permanentAddress: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CreatePostInput = {
  content: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateTripDto = {
  continent: ContinentEnum;
  country: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  excludedServices?: InputMaybe<Array<Scalars['String']['input']>>;
  guidesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  includedServices?: InputMaybe<Array<Scalars['String']['input']>>;
  numberOfPeople: Scalars['Int']['input'];
  price: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
  temporaryBookingLink?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  tripCategories?: InputMaybe<Array<TripCategoryEnum>>;
};

export type CreateTripParticipantInput = {
  /** Example field (placeholder) */
  exampleField: Scalars['Int']['input'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Membership = {
  __typename?: 'Membership';
  approvedBy?: Maybe<User>;
  approvedById?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  status: MembershipStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};

export type MembershipConnection = {
  __typename?: 'MembershipConnection';
  edges?: Maybe<Array<MembershipEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MembershipEdge = {
  __typename?: 'MembershipEdge';
  cursor: Scalars['String']['output'];
  node: Membership;
};

export type MembershipOrder = {
  direction: OrderDirection;
  field: MembershipOrderField;
};

/** Properties by which post connections can be ordered. */
export enum MembershipOrderField {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

/** Membership status enum */
export enum MembershipStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptMembershipRequest: Membership;
  acceptParticipation: TripParticipant;
  approveTrip: Trip;
  changePassword: User;
  changeTripStatus: Trip;
  createPost: Post;
  createTrip: Trip;
  createTripParticipant: TripParticipant;
  deleteMembership: Membership;
  deleteUser: User;
  login: Auth;
  membershipRequest: Membership;
  refreshToken: Token;
  removeTripParticipant: TripParticipant;
  requestParticipation: TripParticipant;
  signup: Auth;
  updateMembershipStatus: Membership;
  updateTrip: Trip;
  updateTripParticipant: TripParticipant;
  updateUser: User;
  updateUserRole: User;
  updateUserRoles: User;
};


export type MutationAcceptMembershipRequestArgs = {
  userId: Scalars['String']['input'];
};


export type MutationAcceptParticipationArgs = {
  input: AcceptParticipationInput;
};


export type MutationApproveTripArgs = {
  id: Scalars['String']['input'];
};


export type MutationChangePasswordArgs = {
  data: ChangePasswordInput;
};


export type MutationChangeTripStatusArgs = {
  id: Scalars['String']['input'];
  status: TripStatusEnum;
};


export type MutationCreatePostArgs = {
  data: CreatePostInput;
};


export type MutationCreateTripArgs = {
  input: CreateTripDto;
};


export type MutationCreateTripParticipantArgs = {
  createTripParticipantInput: CreateTripParticipantInput;
};


export type MutationDeleteMembershipArgs = {
  userId: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationMembershipRequestArgs = {
  data: CreateMembershipInput;
};


export type MutationRefreshTokenArgs = {
  token: Scalars['JWT']['input'];
};


export type MutationRemoveTripParticipantArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRequestParticipationArgs = {
  input: RequestParticipationInput;
};


export type MutationSignupArgs = {
  data: SignupInput;
};


export type MutationUpdateMembershipStatusArgs = {
  status: MembershipStatusEnum;
  userId: Scalars['String']['input'];
};


export type MutationUpdateTripArgs = {
  data: UpdateTripDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateTripParticipantArgs = {
  updateTripParticipantInput: UpdateTripParticipantInput;
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};


export type MutationUpdateUserRoleArgs = {
  id: Scalars['String']['input'];
  role: Role;
};


export type MutationUpdateUserRolesArgs = {
  action: Scalars['String']['input'];
  id: Scalars['String']['input'];
  role: Role;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum ParticipantStatusEnum {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum PaymentStatusEnum {
  Cancelled = 'CANCELLED',
  Paid = 'PAID',
  Pending = 'PENDING'
}

export type Post = {
  __typename?: 'Post';
  author?: Maybe<User>;
  content?: Maybe<Scalars['String']['output']>;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type PostConnection = {
  __typename?: 'PostConnection';
  edges?: Maybe<Array<PostEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PostEdge = {
  __typename?: 'PostEdge';
  cursor: Scalars['String']['output'];
  node: Post;
};

export type PostOrder = {
  direction: OrderDirection;
  field: PostOrderField;
};

/** Properties by which post connections can be ordered. */
export enum PostOrderField {
  Content = 'content',
  CreatedAt = 'createdAt',
  Id = 'id',
  Published = 'published',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type Query = {
  __typename?: 'Query';
  activeTrips: TripConnection;
  hello: Scalars['String']['output'];
  helloWorld: Scalars['String']['output'];
  me: User;
  membership: Membership;
  memberships: MembershipConnection;
  myMembership: Membership;
  myOrganizedTrips: TripConnection;
  post: Post;
  publishedPosts: PostConnection;
  trip: Trip;
  tripParticipant: TripParticipant;
  tripParticipants: Array<TripParticipant>;
  trips: TripConnection;
  user: User;
  userPosts: Array<Post>;
  users: UserConnection;
};


export type QueryActiveTripsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TripOrder>;
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHelloArgs = {
  name: Scalars['String']['input'];
};


export type QueryMembershipArgs = {
  userId: Scalars['String']['input'];
};


export type QueryMembershipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MembershipOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  status: MembershipStatusEnum;
};


export type QueryMyOrganizedTripsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TripOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPublishedPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PostOrder>;
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTripArgs = {
  id: Scalars['String']['input'];
};


export type QueryTripParticipantArgs = {
  id: Scalars['Int']['input'];
};


export type QueryTripParticipantsArgs = {
  tripId: Scalars['String']['input'];
};


export type QueryTripsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TripOrder>;
  queryString?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<TripStatusEnum>;
};


export type QueryUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrder>;
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type RequestParticipationInput = {
  /** Motivation for joining the trip */
  motivation?: InputMaybe<Scalars['String']['input']>;
  tripId: Scalars['String']['input'];
};

/** User role */
export enum Role {
  Admin = 'ADMIN',
  Guide = 'GUIDE',
  Member = 'MEMBER',
  Organizer = 'ORGANIZER',
  User = 'USER'
}

export type SignupInput = {
  email: Scalars['String']['input'];
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  postCreated: Post;
};

export type Token = {
  __typename?: 'Token';
  /** JWT access token */
  accessToken: Scalars['JWT']['output'];
  /** JWT refresh token */
  refreshToken: Scalars['JWT']['output'];
};

export type Trip = {
  __typename?: 'Trip';
  approvedParticipantsCount: Scalars['Int']['output'];
  continent: ContinentEnum;
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTime']['output'];
  excludedServices: Array<Scalars['String']['output']>;
  guides?: Maybe<Array<User>>;
  id: Scalars['String']['output'];
  images: Array<Scalars['String']['output']>;
  includedServices: Array<Scalars['String']['output']>;
  invoices?: Maybe<Array<Scalars['String']['output']>>;
  numberOfPeople: Scalars['Int']['output'];
  organizer: User;
  price: Scalars['Int']['output'];
  reviews?: Maybe<Array<Scalars['String']['output']>>;
  startDate: Scalars['DateTime']['output'];
  status: TripStatusEnum;
  temporaryBookingLink?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  tripCategories?: Maybe<Array<TripCategoryEnum>>;
  tripParticipant?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum TripCategoryEnum {
  Adventure = 'ADVENTURE',
  Beer = 'BEER',
  Camping = 'CAMPING',
  Cruise = 'CRUISE',
  Cultural = 'CULTURAL',
  Diving = 'DIVING',
  Festival = 'FESTIVAL',
  Food = 'FOOD',
  Hiking = 'HIKING',
  Kitesurf = 'KITESURF',
  Relax = 'RELAX',
  Roadtrip = 'ROADTRIP',
  Safari = 'SAFARI',
  Sailing = 'SAILING',
  Ski = 'SKI',
  Snowboard = 'SNOWBOARD',
  Spa = 'SPA',
  Sport = 'SPORT',
  Surftrip = 'SURFTRIP',
  Wellness = 'WELLNESS',
  Wildlife = 'WILDLIFE',
  Wine = 'WINE',
  Yoga = 'YOGA'
}

export type TripConnection = {
  __typename?: 'TripConnection';
  edges?: Maybe<Array<TripEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TripEdge = {
  __typename?: 'TripEdge';
  cursor: Scalars['String']['output'];
  node: Trip;
};

export type TripOrder = {
  direction: OrderDirection;
  field: TripOrderField;
};

/** Properties by which post connections can be ordered. */
export enum TripOrderField {
  Continent = 'continent',
  Country = 'country',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Status = 'status',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type TripParticipant = {
  __typename?: 'TripParticipant';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  paymentStatus: PaymentStatusEnum;
  status: ParticipantStatusEnum;
  tripId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export enum TripStatusEnum {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Finished = 'FINISHED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type UpdateTripDto = {
  country?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  excludedServices?: InputMaybe<Array<Scalars['String']['input']>>;
  guidesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  includedServices?: InputMaybe<Array<Scalars['String']['input']>>;
  numberOfPeople?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  temporaryBookingLink?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  tripCategories?: InputMaybe<Array<TripCategoryEnum>>;
};

export type UpdateTripParticipantInput = {
  /** Example field (placeholder) */
  exampleField?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

export type UpdateUserInput = {
  birthDate?: InputMaybe<Scalars['String']['input']>;
  contactAddress?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  birthDate?: Maybe<Scalars['String']['output']>;
  contactAddress?: Maybe<Scalars['String']['output']>;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstname?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastname?: Maybe<Scalars['String']['output']>;
  permanentAddress?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  posts?: Maybe<Array<Post>>;
  role: Role;
  roles: Array<Role>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges?: Maybe<Array<UserEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserOrder = {
  direction: OrderDirection;
  field: UserOrderField;
};

/** Properties by which post connections can be ordered. */
export enum UserOrderField {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'Auth', accessToken: any, refreshToken: any, user: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> } } };

export type SignupMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type SignupMutation = { __typename?: 'Mutation', signup: { __typename?: 'Auth', accessToken: any, refreshToken: any, user: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> } } };

export type MembershipRequestMutationVariables = Exact<{
  data: CreateMembershipInput;
}>;


export type MembershipRequestMutation = { __typename?: 'Mutation', membershipRequest: { __typename?: 'Membership', id: string, status: MembershipStatusEnum, createdAt: any, user?: { __typename?: 'User', id: string, email: string } | null } };

export type AcceptMembershipRequestMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type AcceptMembershipRequestMutation = { __typename?: 'Mutation', acceptMembershipRequest: { __typename?: 'Membership', id: string, status: MembershipStatusEnum, updatedAt: any, approvedBy?: { __typename?: 'User', id: string, email: string } | null } };

export type UpdateMembershipStatusMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  status: MembershipStatusEnum;
}>;


export type UpdateMembershipStatusMutation = { __typename?: 'Mutation', updateMembershipStatus: { __typename?: 'Membership', id: string, status: MembershipStatusEnum, updatedAt: any } };

export type MyMembershipQueryVariables = Exact<{ [key: string]: never; }>;


export type MyMembershipQuery = { __typename?: 'Query', myMembership: { __typename?: 'Membership', id: string, status: MembershipStatusEnum, createdAt: any, approvedBy?: { __typename?: 'User', id: string, firstname?: string | null, lastname?: string | null } | null } };

export type MembershipsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<MembershipOrder>;
  status: MembershipStatusEnum;
}>;


export type MembershipsQuery = { __typename?: 'Query', memberships: { __typename?: 'MembershipConnection', edges?: Array<{ __typename?: 'MembershipEdge', node: { __typename?: 'Membership', id: string, status: MembershipStatusEnum, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> } | null } }> | null } };

export type PendingMembershipsQueryVariables = Exact<{ [key: string]: never; }>;


export type PendingMembershipsQuery = { __typename?: 'Query', memberships: { __typename?: 'MembershipConnection', edges?: Array<{ __typename?: 'MembershipEdge', node: { __typename?: 'Membership', id: string, status: MembershipStatusEnum, createdAt: any, user?: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> } | null } }> | null } };

export type MembershipQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type MembershipQuery = { __typename?: 'Query', membership: { __typename?: 'Membership', id: string, status: MembershipStatusEnum, createdAt: any, approvedBy?: { __typename?: 'User', firstname?: string | null, lastname?: string | null } | null, user?: { __typename?: 'User', firstname?: string | null, lastname?: string | null } | null } };

export type DeleteMembershipMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type DeleteMembershipMutation = { __typename?: 'Mutation', deleteMembership: { __typename?: 'Membership', id: string, status: MembershipStatusEnum } };

export type TripDataFragment = { __typename?: 'Trip', id: string, title: string, description?: string | null, startDate: any, endDate: any, price: number, numberOfPeople: number, temporaryBookingLink?: string | null, continent: ContinentEnum, country: string, images: Array<string>, includedServices: Array<string>, excludedServices: Array<string>, status: TripStatusEnum, createdAt: any, updatedAt: any, organizer: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> }, guides?: Array<{ __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> }> | null };

export type CreateTripMutationVariables = Exact<{
  input: CreateTripDto;
}>;


export type CreateTripMutation = { __typename?: 'Mutation', createTrip: { __typename?: 'Trip', id: string, title: string, description?: string | null, startDate: any, endDate: any, price: number, numberOfPeople: number, temporaryBookingLink?: string | null, continent: ContinentEnum, country: string, images: Array<string>, includedServices: Array<string>, excludedServices: Array<string>, status: TripStatusEnum, createdAt: any, updatedAt: any, organizer: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> }, guides?: Array<{ __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> }> | null } };

export type ActiveTripsConnectionQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveTripsConnectionQuery = { __typename?: 'Query', activeTrips: { __typename?: 'TripConnection', totalCount: number, edges?: Array<{ __typename?: 'TripEdge', cursor: string, node: { __typename?: 'Trip', id: string, title: string, description?: string | null, startDate: any, endDate: any, price: number, numberOfPeople: number, temporaryBookingLink?: string | null, continent: ContinentEnum, country: string, images: Array<string>, includedServices: Array<string>, excludedServices: Array<string>, status: TripStatusEnum, createdAt: any, updatedAt: any, organizer: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> }, guides?: Array<{ __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> }> | null } }> | null, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type TripsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<TripOrder>;
  status?: InputMaybe<TripStatusEnum>;
}>;


export type TripsQuery = { __typename?: 'Query', trips: { __typename?: 'TripConnection', totalCount: number, edges?: Array<{ __typename?: 'TripEdge', cursor: string, node: { __typename?: 'Trip', id: string, title: string, description?: string | null, startDate: any, endDate: any, price: number, numberOfPeople: number, temporaryBookingLink?: string | null, continent: ContinentEnum, country: string, images: Array<string>, includedServices: Array<string>, excludedServices: Array<string>, status: TripStatusEnum, createdAt: any, updatedAt: any, organizer: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> }, guides?: Array<{ __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> }> | null } }> | null, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type ChangeTripStatusMutationVariables = Exact<{
  id: Scalars['String']['input'];
  status: TripStatusEnum;
}>;


export type ChangeTripStatusMutation = { __typename?: 'Mutation', changeTripStatus: { __typename?: 'Trip', id: string, title: string, description?: string | null, startDate: any, endDate: any, price: number, numberOfPeople: number, temporaryBookingLink?: string | null, continent: ContinentEnum, country: string, images: Array<string>, includedServices: Array<string>, excludedServices: Array<string>, status: TripStatusEnum, createdAt: any, updatedAt: any, organizer: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> }, guides?: Array<{ __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> }> | null } };

export type TripQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type TripQuery = { __typename?: 'Query', trip: { __typename?: 'Trip', id: string, title: string, description?: string | null, startDate: any, endDate: any, price: number, numberOfPeople: number, temporaryBookingLink?: string | null, continent: ContinentEnum, country: string, images: Array<string>, includedServices: Array<string>, excludedServices: Array<string>, status: TripStatusEnum, createdAt: any, updatedAt: any, organizer: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> }, guides?: Array<{ __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> }> | null } };

export type UpdateTripMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: UpdateTripDto;
}>;


export type UpdateTripMutation = { __typename?: 'Mutation', updateTrip: { __typename?: 'Trip', id: string, title: string, description?: string | null, startDate: any, endDate: any, price: number, numberOfPeople: number, temporaryBookingLink?: string | null, continent: ContinentEnum, country: string, images: Array<string>, includedServices: Array<string>, excludedServices: Array<string>, status: TripStatusEnum, createdAt: any, updatedAt: any, organizer: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> }, guides?: Array<{ __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> }> | null } };

export type UserDataFragment = { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> } };

export type UpdateUserMutationVariables = Exact<{
  data: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> } };

export type UsersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<UserOrder>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', totalCount: number, edges?: Array<{ __typename?: 'UserEdge', cursor: string, node: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> } }> | null, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type UserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> } };

export type UpdateUserRoleMutationVariables = Exact<{
  id: Scalars['String']['input'];
  role: Role;
}>;


export type UpdateUserRoleMutation = { __typename?: 'Mutation', updateUserRole: { __typename?: 'User', id: string, email: string, firstname?: string | null, lastname?: string | null, phone?: string | null, birthDate?: string | null, permanentAddress?: string | null, contactAddress?: string | null, createdAt: any, role: Role, roles: Array<Role> } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id: string } };

export const UserDataFragmentDoc = gql`
    fragment UserData on User {
  id
  email
  firstname
  lastname
  phone
  birthDate
  permanentAddress
  contactAddress
  createdAt
  role
  roles
}
    `;
export const TripDataFragmentDoc = gql`
    fragment TripData on Trip {
  id
  title
  description
  startDate
  endDate
  price
  numberOfPeople
  temporaryBookingLink
  continent
  country
  images
  includedServices
  excludedServices
  status
  createdAt
  updatedAt
  organizer {
    ...UserData
  }
  guides {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(data: {email: $email, password: $password}) {
    accessToken
    refreshToken
    user {
      id
      email
      firstname
      lastname
      phone
      birthDate
      permanentAddress
      contactAddress
      createdAt
      role
      roles
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const SignupDocument = gql`
    mutation Signup($email: String!, $password: String!) {
  signup(data: {email: $email, password: $password}) {
    accessToken
    refreshToken
    user {
      id
      email
      firstname
      lastname
      phone
      birthDate
      permanentAddress
      contactAddress
      createdAt
      role
      roles
    }
  }
}
    `;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const MembershipRequestDocument = gql`
    mutation MembershipRequest($data: CreateMembershipInput!) {
  membershipRequest(data: $data) {
    id
    status
    createdAt
    user {
      id
      email
    }
  }
}
    `;
export type MembershipRequestMutationFn = Apollo.MutationFunction<MembershipRequestMutation, MembershipRequestMutationVariables>;

/**
 * __useMembershipRequestMutation__
 *
 * To run a mutation, you first call `useMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [membershipRequestMutation, { data, loading, error }] = useMembershipRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMembershipRequestMutation(baseOptions?: Apollo.MutationHookOptions<MembershipRequestMutation, MembershipRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MembershipRequestMutation, MembershipRequestMutationVariables>(MembershipRequestDocument, options);
      }
export type MembershipRequestMutationHookResult = ReturnType<typeof useMembershipRequestMutation>;
export type MembershipRequestMutationResult = Apollo.MutationResult<MembershipRequestMutation>;
export type MembershipRequestMutationOptions = Apollo.BaseMutationOptions<MembershipRequestMutation, MembershipRequestMutationVariables>;
export const AcceptMembershipRequestDocument = gql`
    mutation AcceptMembershipRequest($userId: String!) {
  acceptMembershipRequest(userId: $userId) {
    id
    status
    updatedAt
    approvedBy {
      id
      email
    }
  }
}
    `;
export type AcceptMembershipRequestMutationFn = Apollo.MutationFunction<AcceptMembershipRequestMutation, AcceptMembershipRequestMutationVariables>;

/**
 * __useAcceptMembershipRequestMutation__
 *
 * To run a mutation, you first call `useAcceptMembershipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptMembershipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptMembershipRequestMutation, { data, loading, error }] = useAcceptMembershipRequestMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAcceptMembershipRequestMutation(baseOptions?: Apollo.MutationHookOptions<AcceptMembershipRequestMutation, AcceptMembershipRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptMembershipRequestMutation, AcceptMembershipRequestMutationVariables>(AcceptMembershipRequestDocument, options);
      }
export type AcceptMembershipRequestMutationHookResult = ReturnType<typeof useAcceptMembershipRequestMutation>;
export type AcceptMembershipRequestMutationResult = Apollo.MutationResult<AcceptMembershipRequestMutation>;
export type AcceptMembershipRequestMutationOptions = Apollo.BaseMutationOptions<AcceptMembershipRequestMutation, AcceptMembershipRequestMutationVariables>;
export const UpdateMembershipStatusDocument = gql`
    mutation UpdateMembershipStatus($userId: String!, $status: MembershipStatusEnum!) {
  updateMembershipStatus(userId: $userId, status: $status) {
    id
    status
    updatedAt
  }
}
    `;
export type UpdateMembershipStatusMutationFn = Apollo.MutationFunction<UpdateMembershipStatusMutation, UpdateMembershipStatusMutationVariables>;

/**
 * __useUpdateMembershipStatusMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipStatusMutation, { data, loading, error }] = useUpdateMembershipStatusMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateMembershipStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipStatusMutation, UpdateMembershipStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembershipStatusMutation, UpdateMembershipStatusMutationVariables>(UpdateMembershipStatusDocument, options);
      }
export type UpdateMembershipStatusMutationHookResult = ReturnType<typeof useUpdateMembershipStatusMutation>;
export type UpdateMembershipStatusMutationResult = Apollo.MutationResult<UpdateMembershipStatusMutation>;
export type UpdateMembershipStatusMutationOptions = Apollo.BaseMutationOptions<UpdateMembershipStatusMutation, UpdateMembershipStatusMutationVariables>;
export const MyMembershipDocument = gql`
    query MyMembership {
  myMembership {
    id
    status
    createdAt
    approvedBy {
      id
      firstname
      lastname
    }
  }
}
    `;

/**
 * __useMyMembershipQuery__
 *
 * To run a query within a React component, call `useMyMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyMembershipQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyMembershipQuery(baseOptions?: Apollo.QueryHookOptions<MyMembershipQuery, MyMembershipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyMembershipQuery, MyMembershipQueryVariables>(MyMembershipDocument, options);
      }
export function useMyMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyMembershipQuery, MyMembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyMembershipQuery, MyMembershipQueryVariables>(MyMembershipDocument, options);
        }
export function useMyMembershipSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyMembershipQuery, MyMembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyMembershipQuery, MyMembershipQueryVariables>(MyMembershipDocument, options);
        }
export type MyMembershipQueryHookResult = ReturnType<typeof useMyMembershipQuery>;
export type MyMembershipLazyQueryHookResult = ReturnType<typeof useMyMembershipLazyQuery>;
export type MyMembershipSuspenseQueryHookResult = ReturnType<typeof useMyMembershipSuspenseQuery>;
export type MyMembershipQueryResult = Apollo.QueryResult<MyMembershipQuery, MyMembershipQueryVariables>;
export const MembershipsDocument = gql`
    query Memberships($first: Int, $after: String, $last: Int, $before: String, $orderBy: MembershipOrder, $status: MembershipStatusEnum!) {
  memberships(
    first: $first
    after: $after
    last: $last
    before: $before
    orderBy: $orderBy
    status: $status
  ) {
    edges {
      node {
        id
        status
        createdAt
        updatedAt
        user {
          ...UserData
        }
      }
    }
  }
}
    ${UserDataFragmentDoc}`;

/**
 * __useMembershipsQuery__
 *
 * To run a query within a React component, call `useMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useMembershipsQuery(baseOptions: Apollo.QueryHookOptions<MembershipsQuery, MembershipsQueryVariables> & ({ variables: MembershipsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipsQuery, MembershipsQueryVariables>(MembershipsDocument, options);
      }
export function useMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipsQuery, MembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipsQuery, MembershipsQueryVariables>(MembershipsDocument, options);
        }
export function useMembershipsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipsQuery, MembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MembershipsQuery, MembershipsQueryVariables>(MembershipsDocument, options);
        }
export type MembershipsQueryHookResult = ReturnType<typeof useMembershipsQuery>;
export type MembershipsLazyQueryHookResult = ReturnType<typeof useMembershipsLazyQuery>;
export type MembershipsSuspenseQueryHookResult = ReturnType<typeof useMembershipsSuspenseQuery>;
export type MembershipsQueryResult = Apollo.QueryResult<MembershipsQuery, MembershipsQueryVariables>;
export const PendingMembershipsDocument = gql`
    query PendingMemberships {
  memberships(
    status: PENDING
    first: 20
    orderBy: {field: createdAt, direction: desc}
  ) {
    edges {
      node {
        id
        status
        createdAt
        user {
          ...UserData
        }
      }
    }
  }
}
    ${UserDataFragmentDoc}`;

/**
 * __usePendingMembershipsQuery__
 *
 * To run a query within a React component, call `usePendingMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingMembershipsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingMembershipsQuery(baseOptions?: Apollo.QueryHookOptions<PendingMembershipsQuery, PendingMembershipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PendingMembershipsQuery, PendingMembershipsQueryVariables>(PendingMembershipsDocument, options);
      }
export function usePendingMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PendingMembershipsQuery, PendingMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PendingMembershipsQuery, PendingMembershipsQueryVariables>(PendingMembershipsDocument, options);
        }
export function usePendingMembershipsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PendingMembershipsQuery, PendingMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PendingMembershipsQuery, PendingMembershipsQueryVariables>(PendingMembershipsDocument, options);
        }
export type PendingMembershipsQueryHookResult = ReturnType<typeof usePendingMembershipsQuery>;
export type PendingMembershipsLazyQueryHookResult = ReturnType<typeof usePendingMembershipsLazyQuery>;
export type PendingMembershipsSuspenseQueryHookResult = ReturnType<typeof usePendingMembershipsSuspenseQuery>;
export type PendingMembershipsQueryResult = Apollo.QueryResult<PendingMembershipsQuery, PendingMembershipsQueryVariables>;
export const MembershipDocument = gql`
    query Membership($userId: String!) {
  membership(userId: $userId) {
    id
    status
    createdAt
    approvedBy {
      firstname
      lastname
    }
    user {
      firstname
      lastname
    }
  }
}
    `;

/**
 * __useMembershipQuery__
 *
 * To run a query within a React component, call `useMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMembershipQuery(baseOptions: Apollo.QueryHookOptions<MembershipQuery, MembershipQueryVariables> & ({ variables: MembershipQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipQuery, MembershipQueryVariables>(MembershipDocument, options);
      }
export function useMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipQuery, MembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipQuery, MembershipQueryVariables>(MembershipDocument, options);
        }
export function useMembershipSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MembershipQuery, MembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MembershipQuery, MembershipQueryVariables>(MembershipDocument, options);
        }
export type MembershipQueryHookResult = ReturnType<typeof useMembershipQuery>;
export type MembershipLazyQueryHookResult = ReturnType<typeof useMembershipLazyQuery>;
export type MembershipSuspenseQueryHookResult = ReturnType<typeof useMembershipSuspenseQuery>;
export type MembershipQueryResult = Apollo.QueryResult<MembershipQuery, MembershipQueryVariables>;
export const DeleteMembershipDocument = gql`
    mutation DeleteMembership($userId: String!) {
  deleteMembership(userId: $userId) {
    id
    status
  }
}
    `;
export type DeleteMembershipMutationFn = Apollo.MutationFunction<DeleteMembershipMutation, DeleteMembershipMutationVariables>;

/**
 * __useDeleteMembershipMutation__
 *
 * To run a mutation, you first call `useDeleteMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMembershipMutation, { data, loading, error }] = useDeleteMembershipMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteMembershipMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMembershipMutation, DeleteMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMembershipMutation, DeleteMembershipMutationVariables>(DeleteMembershipDocument, options);
      }
export type DeleteMembershipMutationHookResult = ReturnType<typeof useDeleteMembershipMutation>;
export type DeleteMembershipMutationResult = Apollo.MutationResult<DeleteMembershipMutation>;
export type DeleteMembershipMutationOptions = Apollo.BaseMutationOptions<DeleteMembershipMutation, DeleteMembershipMutationVariables>;
export const CreateTripDocument = gql`
    mutation CreateTrip($input: CreateTripDto!) {
  createTrip(input: $input) {
    ...TripData
  }
}
    ${TripDataFragmentDoc}`;
export type CreateTripMutationFn = Apollo.MutationFunction<CreateTripMutation, CreateTripMutationVariables>;

/**
 * __useCreateTripMutation__
 *
 * To run a mutation, you first call `useCreateTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTripMutation, { data, loading, error }] = useCreateTripMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTripMutation(baseOptions?: Apollo.MutationHookOptions<CreateTripMutation, CreateTripMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTripMutation, CreateTripMutationVariables>(CreateTripDocument, options);
      }
export type CreateTripMutationHookResult = ReturnType<typeof useCreateTripMutation>;
export type CreateTripMutationResult = Apollo.MutationResult<CreateTripMutation>;
export type CreateTripMutationOptions = Apollo.BaseMutationOptions<CreateTripMutation, CreateTripMutationVariables>;
export const ActiveTripsConnectionDocument = gql`
    query ActiveTripsConnection {
  activeTrips(first: 20, orderBy: {field: title, direction: desc}) {
    totalCount
    edges {
      cursor
      node {
        ...TripData
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${TripDataFragmentDoc}`;

/**
 * __useActiveTripsConnectionQuery__
 *
 * To run a query within a React component, call `useActiveTripsConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveTripsConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveTripsConnectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveTripsConnectionQuery(baseOptions?: Apollo.QueryHookOptions<ActiveTripsConnectionQuery, ActiveTripsConnectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveTripsConnectionQuery, ActiveTripsConnectionQueryVariables>(ActiveTripsConnectionDocument, options);
      }
export function useActiveTripsConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveTripsConnectionQuery, ActiveTripsConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveTripsConnectionQuery, ActiveTripsConnectionQueryVariables>(ActiveTripsConnectionDocument, options);
        }
export function useActiveTripsConnectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ActiveTripsConnectionQuery, ActiveTripsConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActiveTripsConnectionQuery, ActiveTripsConnectionQueryVariables>(ActiveTripsConnectionDocument, options);
        }
export type ActiveTripsConnectionQueryHookResult = ReturnType<typeof useActiveTripsConnectionQuery>;
export type ActiveTripsConnectionLazyQueryHookResult = ReturnType<typeof useActiveTripsConnectionLazyQuery>;
export type ActiveTripsConnectionSuspenseQueryHookResult = ReturnType<typeof useActiveTripsConnectionSuspenseQuery>;
export type ActiveTripsConnectionQueryResult = Apollo.QueryResult<ActiveTripsConnectionQuery, ActiveTripsConnectionQueryVariables>;
export const TripsDocument = gql`
    query Trips($first: Int, $after: String, $last: Int, $before: String, $orderBy: TripOrder, $status: TripStatusEnum) {
  trips(
    first: $first
    after: $after
    last: $last
    before: $before
    orderBy: $orderBy
    status: $status
  ) {
    totalCount
    edges {
      cursor
      node {
        ...TripData
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${TripDataFragmentDoc}`;

/**
 * __useTripsQuery__
 *
 * To run a query within a React component, call `useTripsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useTripsQuery(baseOptions?: Apollo.QueryHookOptions<TripsQuery, TripsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TripsQuery, TripsQueryVariables>(TripsDocument, options);
      }
export function useTripsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TripsQuery, TripsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TripsQuery, TripsQueryVariables>(TripsDocument, options);
        }
export function useTripsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TripsQuery, TripsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TripsQuery, TripsQueryVariables>(TripsDocument, options);
        }
export type TripsQueryHookResult = ReturnType<typeof useTripsQuery>;
export type TripsLazyQueryHookResult = ReturnType<typeof useTripsLazyQuery>;
export type TripsSuspenseQueryHookResult = ReturnType<typeof useTripsSuspenseQuery>;
export type TripsQueryResult = Apollo.QueryResult<TripsQuery, TripsQueryVariables>;
export const ChangeTripStatusDocument = gql`
    mutation ChangeTripStatus($id: String!, $status: TripStatusEnum!) {
  changeTripStatus(id: $id, status: $status) {
    ...TripData
  }
}
    ${TripDataFragmentDoc}`;
export type ChangeTripStatusMutationFn = Apollo.MutationFunction<ChangeTripStatusMutation, ChangeTripStatusMutationVariables>;

/**
 * __useChangeTripStatusMutation__
 *
 * To run a mutation, you first call `useChangeTripStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTripStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTripStatusMutation, { data, loading, error }] = useChangeTripStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useChangeTripStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTripStatusMutation, ChangeTripStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTripStatusMutation, ChangeTripStatusMutationVariables>(ChangeTripStatusDocument, options);
      }
export type ChangeTripStatusMutationHookResult = ReturnType<typeof useChangeTripStatusMutation>;
export type ChangeTripStatusMutationResult = Apollo.MutationResult<ChangeTripStatusMutation>;
export type ChangeTripStatusMutationOptions = Apollo.BaseMutationOptions<ChangeTripStatusMutation, ChangeTripStatusMutationVariables>;
export const TripDocument = gql`
    query Trip($id: String!) {
  trip(id: $id) {
    ...TripData
  }
}
    ${TripDataFragmentDoc}`;

/**
 * __useTripQuery__
 *
 * To run a query within a React component, call `useTripQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTripQuery(baseOptions: Apollo.QueryHookOptions<TripQuery, TripQueryVariables> & ({ variables: TripQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TripQuery, TripQueryVariables>(TripDocument, options);
      }
export function useTripLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TripQuery, TripQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TripQuery, TripQueryVariables>(TripDocument, options);
        }
export function useTripSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TripQuery, TripQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TripQuery, TripQueryVariables>(TripDocument, options);
        }
export type TripQueryHookResult = ReturnType<typeof useTripQuery>;
export type TripLazyQueryHookResult = ReturnType<typeof useTripLazyQuery>;
export type TripSuspenseQueryHookResult = ReturnType<typeof useTripSuspenseQuery>;
export type TripQueryResult = Apollo.QueryResult<TripQuery, TripQueryVariables>;
export const UpdateTripDocument = gql`
    mutation UpdateTrip($id: String!, $data: UpdateTripDto!) {
  updateTrip(id: $id, data: $data) {
    ...TripData
  }
}
    ${TripDataFragmentDoc}`;
export type UpdateTripMutationFn = Apollo.MutationFunction<UpdateTripMutation, UpdateTripMutationVariables>;

/**
 * __useUpdateTripMutation__
 *
 * To run a mutation, you first call `useUpdateTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTripMutation, { data, loading, error }] = useUpdateTripMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTripMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTripMutation, UpdateTripMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTripMutation, UpdateTripMutationVariables>(UpdateTripDocument, options);
      }
export type UpdateTripMutationHookResult = ReturnType<typeof useUpdateTripMutation>;
export type UpdateTripMutationResult = Apollo.MutationResult<UpdateTripMutation>;
export type UpdateTripMutationOptions = Apollo.BaseMutationOptions<UpdateTripMutation, UpdateTripMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($data: UpdateUserInput!) {
  updateUser(data: $data) {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UsersDocument = gql`
    query Users($first: Int, $after: String, $last: Int, $before: String, $orderBy: UserOrder) {
  users(
    first: $first
    after: $after
    last: $last
    before: $before
    orderBy: $orderBy
  ) {
    totalCount
    edges {
      cursor
      node {
        ...UserData
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${UserDataFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserDocument = gql`
    query User($id: String!) {
  user(id: $id) {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> & ({ variables: UserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export function useUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UpdateUserRoleDocument = gql`
    mutation UpdateUserRole($id: String!, $role: Role!) {
  updateUserRole(id: $id, role: $role) {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;
export type UpdateUserRoleMutationFn = Apollo.MutationFunction<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument, options);
      }
export type UpdateUserRoleMutationHookResult = ReturnType<typeof useUpdateUserRoleMutation>;
export type UpdateUserRoleMutationResult = Apollo.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = Apollo.BaseMutationOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(id: $id) {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;