// src/pages/TripEditPage.tsx

import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  CircularProgress,
  Container,
  Alert,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { TRIP_QUERY } from "../../graphql/trips";
import { Trip } from "../../generated/graphql";
import EditTripForm from "../../components/Trips/EditTripForm";

const TripEditPage: React.FC = () => {
  const { tripId } = useParams<{ tripId: string }>();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery(TRIP_QUERY, {
    variables: { id: tripId },
    skip: !tripId, // Skip the query if no ID is provided
  });

  if (loading) {
    return (
      <Container sx={{ textAlign: "center", mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    console.error(error);
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="error">Error loading trip data.</Alert>
      </Container>
    );
  }

  const trip: Trip | undefined = data?.trip;

  if (!trip) {
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="error">Trip not found.</Alert>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4">Edit Trip</Typography>
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Back
        </Button>
      </Box>
      <EditTripForm trip={trip} />
    </Container>
  );
};

export default TripEditPage;
