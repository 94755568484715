import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  Container,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Button,
  Alert,
  Grid,
  Box,
  LinearProgress,
  Avatar,
  Divider,
} from "@mui/material";
import {
  MY_MEMBERSHIP_QUERY,
  MEMBERSHIP_REQUEST_MUTATION,
} from "../../graphql/membership";
import MembershipModal from "../../components/Memberships/MembershipModal";
import { Membership, User } from "../../generated/graphql";

const MembershipDashboardPage: React.FC = () => {
  const { data, loading, error } = useQuery(MY_MEMBERSHIP_QUERY);
  const [requestMembership] = useMutation(MEMBERSHIP_REQUEST_MUTATION, {
    refetchQueries: [{ query: MY_MEMBERSHIP_QUERY }],
  });
  const [isModalOpen, setModalOpen] = useState(false);

  const handleRequestMembership = (formData: any) => {
    requestMembership({ variables: { data: formData } })
      .then(() => {
        console.log("Membership request sent");
      })
      .catch((err) => {
        console.error("Error requesting membership:", err);
      });
  };

  const handleCancelMembership = () => {
    console.log("Cancelling membership");
    // Implement the cancel membership functionality
  };

  if (loading)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );

  // Check if the error is related to "No membership found"
  if (error && error.message.includes("No membership found")) {
    return (
      <NoMembership
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        handleRequestMembership={handleRequestMembership}
      />
    );
  }

  // Handle other potential errors
  if (error) return <Alert severity="error">{error.message}</Alert>;

  const membership = data.myMembership;

  if (membership.status === "PENDING") {
    // Membership is pending approval
    return <PendingMembership />;
  }

  if (membership.status === "ACTIVE") {
    // Membership is active
    return (
      <ActiveMembershipDashboard
        membership={membership}
        handleCancelMembership={handleCancelMembership}
      />
    );
  }

  return null;
};

// Component for users with no membership
const NoMembership = ({
  isModalOpen,
  setModalOpen,
  handleRequestMembership,
}: {
  isModalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleRequestMembership: (formData: any) => void;
}) => {
  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Become a Member
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        It looks like you haven't requested membership yet. To enjoy exclusive
        benefits, please request membership.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => setModalOpen(true)}
      >
        Request Membership
      </Button>
      <MembershipModal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        onAccept={handleRequestMembership}
      />
    </Container>
  );
};

// Component for users with pending membership
const PendingMembership = () => {
  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Membership Status: Pending
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        Your membership request is currently pending approval. Please wait for
        an admin to review your request.
      </Typography>
    </Container>
  );
};

const getFullname = (user: User | null | undefined) => {
  if (!user) return "";

  return `${user?.firstname ? user?.firstname : ""} ${
    user?.lastname ? user?.lastname : ""
  }`;
};

// Component for users with active membership
const ActiveMembershipDashboard = ({
  membership,
  handleCancelMembership,
}: {
  membership: Membership;
  handleCancelMembership: () => void;
}) => {
  // TODO  Placeholder data for loyalty program and trips
  const loyaltyLevel = "Beginner";
  const loyaltyPoints = 150;
  const progressToNextLevel = 30; // TODO  Percentage
  const upcomingTrips: any[] = []; //TODO   Replace with actual data
  const pastTrips: any[] = []; //TODO Replace with actual data
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")!)
    : null;

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {/* Header */}
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Avatar
          src={"/default-avatar.png"} // TODO Replace with user.avatar
          sx={{ width: 100, height: 100, mx: "auto", mb: 2 }}
        />
        <Typography variant="h5" gutterBottom>
          Welcome, {getFullname(user)}!
        </Typography>
        <Typography variant="body1" color="text">
          Email: {user.email}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1" color="textSecondary">
          Member since {new Date(membership.createdAt).toLocaleDateString()}
        </Typography>
      </Box>

      {/* Dashboard Content */}
      <Grid container spacing={3}>
        {/* Membership Info */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Membership Information
              </Typography>
              <Typography variant="body1">Status: Active</Typography>
              <Typography variant="body1">
                Member ID: {membership.id}
              </Typography>
              {membership.approvedBy && (
                <Typography variant="body1">
                  Approved By: {membership.approvedBy.firstname}{" "}
                  {membership.approvedBy.lastname}
                </Typography>
              )}
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={handleCancelMembership}
                sx={{ mt: 2 }}
              >
                Cancel Membership
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Loyalty Program */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Loyalty Program
              </Typography>
              <Typography variant="body1">Level: {loyaltyLevel}</Typography>
              <Typography variant="body1">Points: {loyaltyPoints}</Typography>
              {/* Progress Bar */}
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="textSecondary">
                  Progress to next level:
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={progressToNextLevel}
                />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: 1 }}
                >
                  {progressToNextLevel}% to {getNextLevel(loyaltyLevel)}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Upcoming Trips */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Upcoming Trips
              </Typography>
              {upcomingTrips.length === 0 ? (
                <>
                  <Typography variant="body1">
                    You have no upcoming trips.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    href="/trips"
                  >
                    Browse Trips
                  </Button>
                </>
              ) : (
                // Render list of upcoming trips
                upcomingTrips.map((trip: any) => (
                  <Typography key={trip.id} variant="body1">
                    {trip.name} - {trip.date}
                  </Typography>
                ))
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Past Trips */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Past Trips
              </Typography>
              {pastTrips.length === 0 ? (
                <Typography variant="body1">
                  You haven't participated in any trips yet.
                </Typography>
              ) : (
                // Render list of past trips
                pastTrips.map((trip: any) => (
                  <Typography key={trip.id} variant="body1">
                    {trip.name} - {trip.date}
                  </Typography>
                ))
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Membership Benefits */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Membership Benefits
              </Typography>
              <ul>
                <li>
                  <Typography variant="body1">
                    Exclusive access to surf camps
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    Discounts on gear and merchandise
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    Member-only community events
                  </Typography>
                </li>
              </ul>
            </CardContent>
          </Card>
        </Grid>

        {/* Account Settings */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Account Settings
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                sx={{ mt: 2 }}
                href="/profile"
              >
                Update Profile
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={{ mt: 2, ml: 2 }}
                href="/change-password"
              >
                Change Password
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

// Helper function to determine the next loyalty level
const getNextLevel = (currentLevel: string): string => {
  const levels = ["Beginner", "Intermediate", "Advanced", "Expert"];
  const currentIndex = levels.indexOf(currentLevel);
  return currentIndex < levels.length - 1
    ? levels[currentIndex + 1]
    : levels[currentIndex];
};

export default MembershipDashboardPage;
