import { gql } from '@apollo/client';

export const USER_DATA_FRAGMENT = gql`
  fragment UserData on User {
    id
    email
    firstname
    lastname
    phone
    birthDate
    permanentAddress
    contactAddress
    createdAt
    role
    roles
  }
`;

export const ME_QUERY = gql`
  query Me {
    me {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const USERS_QUERY = gql`
  query Users(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $orderBy: UserOrder
  ) {
    users(
      first: $first
      after: $after
      last: $last
      before: $before
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        cursor
        node {
          ...UserData
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const USER_QUERY = gql`
  query User($id: String!) {
    user(id: $id) {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const UPDATE_USER_ROLE_MUTATION = gql`
  mutation UpdateUserRole($id: String!, $role: Role!) {
    updateUserRole(id: $id, role: $role) {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      id
    }
  }
`;
