import { gql } from "@apollo/client";
import { USER_DATA_FRAGMENT } from "./users";

export const MEMBERSHIP_REQUEST_MUTATION = gql`
  mutation MembershipRequest($data: CreateMembershipInput!) {
    membershipRequest(data: $data) {
      id
      status
      createdAt
      user {
        id
        email
      }
    }
  }
`;

export const ACCEPT_MEMBERSHIP_REQUEST_MUTATION = gql`
  mutation AcceptMembershipRequest($userId: String!) {
    acceptMembershipRequest(userId: $userId) {
      id
      status
      updatedAt
      approvedBy {
        id
        email
      }
    }
  }
`;

export const UPDATE_MEMBERSHIP_STATUS_MUTATION = gql`
  mutation UpdateMembershipStatus(
    $userId: String!
    $status: MembershipStatusEnum!
  ) {
    updateMembershipStatus(userId: $userId, status: $status) {
      id
      status
      updatedAt
    }
  }
`;

export const MY_MEMBERSHIP_QUERY = gql`
  query MyMembership {
    myMembership {
      id
      status
      createdAt
      approvedBy {
        id
        firstname
        lastname
      }
    }
  }
`;

export const MEMBERSHIPS_QUERY = gql`
  query Memberships(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $orderBy: MembershipOrder
    $status: MembershipStatusEnum!
  ) {
    memberships(
      first: $first
      after: $after
      last: $last
      before: $before
      orderBy: $orderBy
      status: $status
    ) {
      edges {
        node {
          id
          status
          createdAt
          updatedAt
          user {
            ...UserData
          }
        }
      }
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const PENDING_MEMBERSHIPS_QUERY = gql`
  query PendingMemberships {
    memberships(
      status: PENDING
      first: 20
      orderBy: { field: createdAt, direction: desc }
    ) {
      edges {
        node {
          id
          status
          createdAt
          user {
            ...UserData
          }
        }
      }
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const MEMBERSHIP_QUERY = gql`
  query Membership($userId: String!) {
    membership(userId: $userId) {
      id
      status
      createdAt
      approvedBy {
        firstname
        lastname
      }
      user {
        firstname
        lastname
      }
    }
  }
`;

export const DELETE_MEMBERSHIP_MUTATION = gql`
  mutation DeleteMembership($userId: String!) {
    deleteMembership(userId: $userId) {
      id
      status
    }
  }
`;
