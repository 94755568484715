// src/components/Trips/TripsListContainer.tsx

import React from "react";
import { useQuery } from "@apollo/client";
import { CircularProgress, Grid, Typography, Button, Box } from "@mui/material";
import TripCard from "./TripCard";
import { ACTIVE_TRIPS_QUERY } from "../../graphql/trips";

const ITEMS_PER_PAGE = 4;

const TripsListContainer: React.FC = () => {
  const { data, loading, error } = useQuery(ACTIVE_TRIPS_QUERY);
  const [visibleTrips, setVisibleTrips] = React.useState(ITEMS_PER_PAGE);

  if (loading) {
    return (
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !data?.activeTrips.edges) {
    return (
      <Typography
        variant="subtitle1"
        color="error"
        sx={{ textAlign: "center", mt: 4 }}
      >
        Unable to load trips. Please try again later.
      </Typography>
    );
  }

  const trips = data.activeTrips.edges.map((edge: any) => edge.node);

  // Check if there are no trips
  if (trips.length === 0) {
    return (
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Typography variant="h6" color="textSecondary">
          Unfortunately, there are no upcoming trips at the moment.
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Please check back later or contact us for more information.
        </Typography>
      </Box>
    );
  }

  const displayedTrips = trips.slice(0, visibleTrips);

  const loadMoreTrips = () => {
    setVisibleTrips((prev) => prev + ITEMS_PER_PAGE);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        {displayedTrips.map((trip: any) => (
          <Grid item key={trip.id} xs={12} sm={6} md={3}>
            <TripCard trip={trip} />
          </Grid>
        ))}
      </Grid>
      {visibleTrips < trips.length && (
        <Box display="flex" justifyContent="center" mt={3}>
          <Button
            variant="contained"
            onClick={loadMoreTrips}
            sx={{
              mb: 6,
              mt: 4,
              color: "#ffffff",
              padding: "10px 20px",
              borderRadius: "25px",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            Load More
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default TripsListContainer;
